<template>
  <section id="recommendation">
    <div>
      <div class="d-flex align-items-center justify-content-between gap-20">
        <h3 class="fontsize-xheavy font-w-900 text-color-666 w-s-nowrap">
          {{ data.group }}
        </h3>
        <div class="custom-line d-none d-xl-block"></div>
        <DynamicLinker v-if="data" :item="data">بیشتر</DynamicLinker>
      </div>
      <div class="swiper-bg">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <Product
              class="swiper-slide"
              v-for="(product, index) in data.products"
              :key="index + 'index'"
              :product="product"
            />
          </div>
        </div>
        <div class="next-btn d-none d-xl-block">
          <svg
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="#444444"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z"
              fill="#444444"
            />
          </svg>
        </div>
        <div class="prev-btn d-none d-xl-block">
          <svg
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="#444444"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z"
              fill="#444444"
            />
          </svg>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Swiper from "swiper/swiper-bundle.min";
import Product from "@/parts/Front/components/product/Product";
import DynamicLinker from "@/parts/Front/components/shared/DynamicLinker.vue";

export default {
  name: "RecommendationSliders",
  components: { Product, DynamicLinker },
  inject: ["mediaQueries"],
  props: {
    data: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  watch: {
    products: {
      immediate: true,
      handler(products) {
        if (!products) {
          return;
        }
        this.initSlider(true);
      },
    },
  },
  mounted() {
    this.initSlider(false);
  },
  computed: {
    products() {
      return this.readyProducts;
    },
  },
  methods: {
    initSlider(kill = false) {
      this.initSwiper("recommendationSlider", kill, () => {
        if (this.slider) {
          this.slider.destroy();
        }
        this.slider = new Swiper("#recommendation .swiper-container", {
          spaceBetween: 1,
          centeredSlides: this.mediaQueries.mobileSize ? true : false,
          centeredSlidesBounds: this.mediaQueries.mobileSize ? true : false,
          loop: false,
          speed: 500,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: "#recommendation .next-btn",
            prevEl: "#recommendation .prev-btn",
          },
          breakpoints: {
            300: {
              slidesPerView: 1.3,
              spaceBetween: 15,
            },
            500: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 4.3,
              spaceBetween: 10,
            },
          },
        });
      });
    },
  },
};
</script>

<style scoped>
#recommendation * {
  margin: 0;
  padding: 0;
}
#recommendation {
  margin-block: 2rem 6rem;
}
#recommendation .swiper-bg {
  padding: 11px 11px;
  background: rgb(21, 190, 149);
  background: linear-gradient(
    45deg,
    rgba(21, 190, 149, 0.55) 30%,
    rgba(241, 114, 172, 0.55) 55%
  );
  border-radius: 40px;
  position: relative;
  margin-top: 2.5rem;
}
#recommendation .swiper-bg .swiper-container {
  padding: 1rem 0.5rem;
  margin-inline: 1.5rem;
  margin-bottom: -3rem;
  margin-top: -3rem;
}

#recommendation .swiper-bg .next-btn {
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  left: 99%;
  padding: 10px;
  cursor: pointer;
}
#recommendation .swiper-bg .prev-btn {
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 99%;
  padding: 10px;
  cursor: pointer;
}
#recommendation .swiper-slide {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
#recommendation .custom-line {
  width: 100%;
  height: 1px;
  border-radius: 25px;
  background-color: #cccccc;
  flex-grow: 1;
}
#recommendation a {
  color: #ffffff;
  border: 1px solid var(--color-main);
  background-color: var(--color-main) !important;
  border-radius: 25px;
  padding: 8px 35px;
}
@media screen and (max-width:600px) {
  #recommendation {
    margin-block: 2rem 4rem;
  }
}
</style>
