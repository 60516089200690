<template>
  <main class="d-flex flex-column align-items-center gap-32 freeSend">
    <template v-if="content">
      <img
        class="d-md-block d-none"
        :src="`https://api.benedito.ir/storage/${content.settings.free_shipping.free_shipping_desktop_banner}`"
        alt="ارسال رایگان بندیتو"
      />
      <img
        class="d-block d-md-none"
        :src="`https://api.benedito.ir/storage/${content.settings.free_shipping.free_shipping_mobile_banner}`"
        alt="ارسال رایگان بندیتو"
      />
    </template>
    <template v-if="products">
      <div v-if="products.length" id="freeProducts">
        <Product
          v-for="(product, index) in products"
          :key="index"
          :product="product"
        />
      </div>
      <p v-else>محصولی برای نمایش وجود ندارد</p>
    </template>
  </main>
</template>
<script>
import Product from "@/parts/Front/components/product/Product";
export default {
  components: {
    Product,
  },
  created() {
    if (!this.products) {
      this.$store.dispatch("front/getFreeShippingProductsFromDB");
    }
  },
  computed: {
    products() {
      return this.$store.getters["front/getFreeShippingProducts"];
    },
    content() {
      return this.$store.getters["front/getHomeData"];
    },
  },
};
</script>
<style scoped>
.freeSend {
  width: 90%;
  margin: 24px auto;
}
div#freeProducts {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  padding: 16px;
}
main > q {
  font-size: 24px;
  text-align: center;
}
@media (max-width: 768px) {
  div#freeProducts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 580px) {
  div#freeProducts {
    display: grid;
    grid-template-columns: repeat(2, 48%);
  }
  main > q {
    font-size: 20px;
  }
}
main > img {
  width: 100%;
}
</style>
<style>
div#freeProducts article {
  overflow: hidden;
  box-shadow: 0 0 10px #989898;
}
</style>
