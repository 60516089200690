<template>
  <a
    title="مقایسه"
    @click="$store.commit('front/toggleCompare', { product, $root })"
    :selected="isComparing"
  >
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 512.000000 512.000000"
      class=""
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#444"
        stroke="none"
      >
        <path
          d="M3883 5101 c-73 -25 -136 -80 -170 -149 -37 -76 -39 -184 -5 -250 61
-115 140 -164 280 -172 l93 -5 -495 -495 c-272 -272 -500 -495 -506 -495 -6 0
-153 142 -328 316 -348 346 -364 358 -481 366 -52 4 -79 0 -126 -17 -58 -21
-88 -50 -777 -738 -511 -510 -725 -730 -745 -766 -24 -44 -28 -62 -28 -131 0
-67 5 -88 27 -130 57 -108 155 -169 268 -168 125 0 104 -17 757 636 l593 592
322 -322 c376 -375 381 -379 522 -371 59 3 93 11 126 28 48 24 625 589 660
646 27 42 25 60 -9 95 -58 57 -54 60 -401 -285 -173 -173 -328 -320 -343 -325
-37 -14 -77 -13 -104 2 -13 7 -182 171 -376 364 -225 224 -362 354 -380 358
-18 5 -36 1 -59 -14 -18 -11 -303 -291 -633 -622 -330 -330 -610 -605 -623
-610 -118 -46 -231 72 -177 185 8 17 329 345 713 728 616 616 701 697 735 703
90 17 83 23 472 -366 335 -333 363 -359 395 -359 33 0 75 40 659 624 l624 624
-6 38 c-11 67 -15 68 -235 74 -221 6 -241 11 -268 75 -20 49 -17 87 9 126 46
70 40 69 542 69 428 0 452 -1 482 -20 66 -40 64 -18 61 -539 l-3 -473 -37 -34
c-32 -29 -45 -34 -85 -34 -60 0 -91 16 -120 59 -22 32 -23 43 -23 217 0 176
-1 184 -22 208 -13 14 -36 28 -51 32 -27 6 -46 -11 -298 -262 -148 -148 -272
-279 -275 -290 -7 -26 19 -79 41 -88 51 -19 65 -9 257 181 l188 187 0 -76 c0
-111 22 -171 90 -239 64 -65 123 -89 214 -89 107 0 208 66 258 169 23 46 23
49 26 545 l3 500 -27 54 c-17 35 -42 65 -73 88 -86 63 -94 64 -610 64 -421 -1
-471 -2 -518 -19z"
        />
        <path
          d="M4157 2849 c-88 -21 -121 -50 -705 -633 -320 -319 -585 -583 -587
-585 -3 -3 -155 144 -338 326 -367 365 -364 363 -492 363 -132 0 -94 32 -795
-661 l-635 -627 -6 92 c-7 107 -23 149 -80 211 -67 73 -107 90 -214 90 -78 0
-97 -4 -141 -27 -28 -15 -70 -48 -93 -74 -70 -80 -71 -87 -71 -610 0 -449 1
-465 21 -521 29 -76 96 -143 173 -172 58 -21 66 -22 540 -19 477 3 481 3 523
26 143 76 205 223 156 368 -11 33 -34 67 -72 105 -69 69 -123 89 -235 89 l-81
0 207 207 c113 115 211 219 218 231 16 32 -3 78 -41 98 -25 12 -33 13 -61 0
-19 -8 -156 -137 -310 -293 -271 -271 -278 -280 -278 -318 0 -79 18 -85 237
-85 196 0 213 -4 250 -59 29 -43 30 -104 3 -149 -38 -61 -42 -62 -537 -62
-502 0 -500 0 -535 70 -16 32 -18 77 -18 483 0 498 0 501 63 530 51 25 128 22
162 -5 48 -38 55 -71 55 -262 0 -169 1 -176 23 -202 17 -19 33 -27 56 -27 37
0 -8 -44 874 833 306 305 567 560 580 567 30 17 92 16 125 -1 15 -8 180 -168
367 -356 187 -188 352 -348 367 -356 20 -10 34 -11 55 -3 15 5 306 288 648
629 l620 620 58 0 c51 0 61 -4 93 -33 43 -39 56 -105 31 -153 -9 -16 -330
-343 -714 -726 -618 -616 -702 -697 -736 -703 -90 -17 -83 -23 -472 366 -330
329 -363 359 -394 359 -30 0 -53 -19 -222 -188 -173 -173 -189 -192 -189 -224
0 -45 34 -78 80 -78 30 0 50 16 185 150 l151 151 318 -325 c364 -373 375 -381
506 -380 138 1 95 -37 892 762 510 511 723 730 739 762 30 61 37 162 15 226
-35 104 -108 172 -212 199 -64 17 -72 17 -127 4z"
        />
      </g>
    </svg>
  </a>
</template>

<script>
export default {
  props: {
    product: Object,
    isComparing:Boolean
  },
};
</script>

<style></style>
