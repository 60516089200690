<template>
    <div
      v-if="banner && banner.picture"
      :class="fullWidth ? 'w-100' : 'home-banner-holder'"
    >
      <DynamicLinker class="d-flex" v-if="banner" :item="banner">
        <img loading="lazy" :src="banner.picture" class="w-100" :alt="banner.position_description" />
      </DynamicLinker>
    </div>
  </template>
  
  <script>
  import DynamicLinker from "@/parts/Front/components/shared/DynamicLinker";
  export default {
    name: "banner",
    components: { DynamicLinker },
    props: {
      banner: Object,
      fullWidth: {
        type: Boolean,
        default: false
      }
    },
  };
  </script>
  <style scoped>
  .home-banner-holder {
      width: 49%;
  }
  @media screen and (max-width: 769px){
      .home-banner-holder {
      width: 100%;
  }
  }
  </style>
  