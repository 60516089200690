<template>
  <section class="recommended-products container">
    <div class="recommended-products-tabs">
      <div
        class="container recommended-products-category d-flex align-items-center justify-content-between"
      >
        <div class="d-flex align-items-center gap-5 text-light">
          <svg
            width="40"
            height="20"
            viewBox="0 0 35 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.4"
              y="0.860815"
              width="34.2"
              height="15.2811"
              rx="7.64054"
              stroke="white"
              stroke-width="0.8"
            />
            <path
              d="M8.388 7.54934V12.0013H6.744V4.65734H8.148L11.196 9.12134V4.65734H12.84V12.0013H11.424L8.388 7.54934ZM19.558 10.6813V12.0013H14.506V4.65734H19.558V5.97734H16.15V7.64534H19.15V9.01334H16.15V10.6813H19.558ZM25.0853 7.69334L23.7892 12.0013H22.2892L20.6692 4.65734H22.2772L23.2372 9.15734L24.2452 5.66534V4.65734H25.5652L26.8133 9.15734L27.7972 4.65734H29.3932L27.7852 12.0013H26.2852L25.0853 7.69334Z"
              fill="white"
            />
          </svg>
          <h3
            class="m-0 d-inline-block fontsize-heavy"
            style="font-weight: 900"
          >
            شگفت انگیز
          </h3>
          <p class="fontsize-small m-0">بندیتو</p>
        </div>
        <div>
          <router-link
            class="py-1 px-2 py-xl-2 px-xl-3 fontsize-mini recommended-product-seemore"
            to="/products?sort=most_discount"
            >مشاهده بیشتر</router-link
          >
        </div>
      </div>
    </div>
    <div class="recommended-content">
      <div class="recommended-content-item position-relative" id="home">
        <div :style="`min-height: ${minHeight}px`">
          <div
            class="swiper-container"
            v-if="!disabledSliders.includes('recommendedProductSlider')"
          >
            <div class="swiper-wrapper">
              <Product
                class="swiper-slide"
                :style="'max-width:' + maxWidth + 'px'"
                v-for="(product, index) in products"
                :key="index + 'index'"
                :product="product"
              />
            </div>
          </div>
          <div v-if="!products">
            <Loading :mode="'dot'" />
          </div>
        </div>
      </div>
    </div>
    <div class="next-btn-holder d-none d-xl-block">
      <div class="next-btn">
        <svg
          width="46"
          height="140"
          viewBox="0 0 46 140"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.0422 123.949C17.2381 106.695 45.9541 94.9242 45.9541 74.1977C45.7061 56.9482 15.3937 45.0078 14.8107 25.0483C7.94466 -47.1794 0.308108 58.5874 0.308109 74.8619C0.30811 91.1365 12.6215 173.304 16.0422 123.949Z"
            fill="#EB6FA7"
          />
        </svg>

        <div class="next-btn-icon"><RightChevIcon /></div>
      </div>
    </div>
    <div class="prev-btn-holder d-none d-xl-block">
      <div class="prev-btn">
        <svg
          width="46"
          height="140"
          viewBox="0 0 46 140"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.0422 123.949C17.2381 106.695 45.9541 94.9242 45.9541 74.1977C45.7061 56.9482 15.3937 45.0078 14.8107 25.0483C7.94466 -47.1794 0.308108 58.5874 0.308109 74.8619C0.30811 91.1365 12.6215 173.304 16.0422 123.949Z"
            fill="#EB6FA7"
          />
        </svg>
        <div class="prev-btn-icon"><RightChevIcon /></div>
      </div>
    </div>
  </section>
</template>

<script>
import Loading from "@/parts/Front/components/shared/Loading";

import Product from "@/parts/Front/components/product/Product";
import Swiper from "swiper/swiper-bundle.min";
import debounce from "lodash/debounce";
import { getImage } from "@/libraries/functions";
import LeftChevIcon from "@/parts/Front/components/Icon/LeftChevIcon.vue";
import RightChevIcon from "@/parts/Front/components/Icon/RightChevIcon.vue";
let breakpoints = {
  300: {
    slidesPerView: 1.2,
    spaceBetween: 15,
  },
  500: {
    slidesPerView: 1.4,
    spaceBetween: 10,
  },
  992: {
    slidesPerView: 4,
    spaceBetween: 10,
  },
  1200: {
    slidesPerView: 4.3,
    spaceBetween: 10,
  },
};

export default {
  name: "RecommendedProductSlider",
  components: { Product, Loading, LeftChevIcon, RightChevIcon },
  inject: ["mediaQueries"],
  data() {
    return {
      minHeight: "unset",
      maxWidth: "unset",
      activeTab: "most-discounts-new",
      readyProducts: null,
      sliderIsLoading: true,
      onResizeFunction: debounce(this.onResize, 150),
    };
  },
  watch: {
    activeTab: {
      handler: function () {
        this.initSlider(true);
      },
    },
    products: {
      immediate: true,
      handler(products) {
        if (!products) {
          return;
        }
        this.initSlider(true);
      },
    },
  },
  mounted() {
    this.setReadyProducts(this.activeTab);
    this.initSlider(false);
    this.setMaxWidth();
    window.addEventListener("resize", this.onResizeFunction);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResizeFunction);
  },
  computed: {
    showCount() {
      return 16;
    },
    products() {
      return this.readyProducts;
    },
  },
  methods: {
    async setReadyProducts(key) {
      this.activeTab = key;
      // localStorage.setItem("homeActiveTab", this.activeTab);
      this.readyProducts = null;
      let res = await this.$store.dispatch("front/getIndexPageContent", key);
      this.readyProducts = res;
    },
    onResize() {
      this.setMaxWidth();
    },
    setMaxWidth() {
      let slidesPerView = this.getSlidesPerView();
      let spaceBetween = slidesPerView.spaceBetween;
      slidesPerView = slidesPerView.slidesPerView;
      this.maxWidth =
        (document.querySelector(".recommended-products").getBoundingClientRect()
          .width -
          spaceBetween * slidesPerView) /
          slidesPerView +
        (window.innerWidth > 768 ? 2 : 0);
    },
    getSlidesPerView() {
      let c = Number.parseInt(window.innerWidth);
      let ok = 1;
      let max = -1;
      Object.keys(breakpoints).forEach((b) => {
        b = Number.parseInt(b);
        if (c > b && b > max) {
          ok = breakpoints[b];
          max = b;
        }
      });
      return ok;
    },
    initSlider(kill = false) {
      this.initSwiper("recommendedProductSlider", kill, () => {
        if (this.slider) {
          this.slider.destroy();
        }
        this.slider = new Swiper(".recommended-products .swiper-container", {
          spaceBetween: 1,
          loop: false,
          speed: 500,
          centeredSlides: this.mediaQueries.mobileSize ? true : false,
          centeredSlidesBounds: this.mediaQueries.mobileSize ? true : false,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: ".recommended-products .next-btn-holder",
            prevEl: ".recommended-products .prev-btn-holder",
          },
          breakpoints,
        });
        setTimeout(() => {
          this.minHeight = document
            .querySelector(".recommended-products .swiper-container")
            .getBoundingClientRect().height;
        }, 1700);
      });
    },
    // گرفتن مقدار لازم براساس تعدادی که باید نمایش بدیم بر اساس سایز صفحه
    getEnough(array) {
      return [...array].splice(0, Math.min(array.length, this.showCount));
    },
  },
};
</script>

<style scoped>
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.recommended-products {
  background-color: var(--color-main);
  border-radius: 50px;
  padding: 0 40px 0 40px;
  position: relative;
}
.swiper-slide {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.recommended-content {
  position: relative;
  top: 60px;
}
.swiper-container {
  animation: fade 450ms;
}
.nav-item {
  border-radius: 25px;
  border: 1px solid #ffffff;
  white-space: nowrap;
}
.recommended-products-tabs {
  position: relative;
  top: 40px;
  right: 0;
}
.prev-btn-holder {
  position: absolute;
  top: 50%;
  right: 98.89%;
  transform: rotateY(180deg);
  /* transform: translateX(1px); */
}
.next-btn-holder {
  position: absolute;
  top: 50%;
  left: 99%;
}
.prev-btn {
  /* background-color: var(--color-main); */
  /* height: 140px; */
  /* width: 120px; */
  position: relative;
  /* -webkit-clip-path: polygon(
    93% 15%,
    100% 0,
    100% 50%,
    100% 100%,
    93% 85%,
    80% 70%,
    73% 60%,
    70% 50%,
    73% 40%,
    80% 30%
  );
  clip-path: polygon(
    93% 15%,
    100% 0,
    100% 50%,
    100% 100%,
    93% 85%,
    80% 70%,
    73% 60%,
    70% 50%,
    73% 40%,
    80% 30%
  ); */
}
.prev-btn-icon {
  position: absolute;
  top: 53%;
  right: 8%;
  transform: translateY(-50%);
}
.next-btn {
  /* background-color: var(--color-main); */
  position: relative;
  /* height: 140px; */
  /* width: 120px; */
  /* -webkit-clip-path: polygon(
    7% 15%,
    0 0,
    0 50%,
    0 100%,
    7% 85%,
    20% 70%,
    27% 60%,
    30% 50%,
    27% 40%,
    20% 30%
  );
  clip-path: polygon(
    7% 15%,
    0 0,
    0 50%,
    0 100%,
    7% 85%,
    20% 70%,
    27% 60%,
    30% 50%,
    27% 40%,
    20% 30%
  ); */
}
.next-btn-icon {
  position: absolute;
  top: 53%;
  left: 8%;
  transform: translateY(-50%);
}
.recommended-product-seemore {
  white-space: nowrap;
  list-style-type: none;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 25px;
  font-weight: bold;
  background-color: transparent;
}
@media (max-width: 1199px) {
  .recommended-products {
    border-radius: unset;
    padding: 0;
  }
  .recommended-content {
    position: relative;
    top: 80px;
    padding-inline: 20px;
  }
}
</style>
