<template>
  <section class="mainProducts">
    <a class="mt-2 mb-2 d-block" :href="item.banner_link">
      <img :src="item.icon.url" :alt="item.title" />
    </a>
    <div :id="`specialCategory_${item.id}`" class="productContainer">
      <div
        class="swiper-container"
        v-if="!disabledSliders.includes('recommendedProductSlider')"
      >
        <div class="swiper-wrapper">
          <Product
            class="swiper-slide"
            v-for="(product, index) in item.products"
            :key="index + 'index'"
            :product="product"
          />
        </div>
      </div>
      <div class="next-btn-holder d-none d-xl-block">
        <div class="next-btn">
          <svg
            width="46"
            height="140"
            viewBox="0 0 46 140"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.0422 123.949C17.2381 106.695 45.9541 94.9242 45.9541 74.1977C45.7061 56.9482 15.3937 45.0078 14.8107 25.0483C7.94466 -47.1794 0.308108 58.5874 0.308109 74.8619C0.30811 91.1365 12.6215 173.304 16.0422 123.949Z"
              fill="#f2f2f2"
            />
          </svg>

          <div class="next-btn-icon"><RightChevIcon fill="#2b2b2b" /></div>
        </div>
      </div>
      <div class="prev-btn-holder d-none d-xl-block">
        <div class="prev-btn">
          <svg
            width="46"
            height="140"
            viewBox="0 0 46 140"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.0422 123.949C17.2381 106.695 45.9541 94.9242 45.9541 74.1977C45.7061 56.9482 15.3937 45.0078 14.8107 25.0483C7.94466 -47.1794 0.308108 58.5874 0.308109 74.8619C0.30811 91.1365 12.6215 173.304 16.0422 123.949Z"
              fill="#f2f2f2"
            />
          </svg>
          <div class="prev-btn-icon"><RightChevIcon fill="#2b2b2b" /></div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Product from "@/parts/Front/components/product/Product";
import Swiper from "swiper/swiper-bundle.min";
import RightChevIcon from "@/parts/Front/components/Icon/RightChevIcon.vue";
let breakpoints = {
  300: {
    slidesPerView: 1.3,
    spaceBetween: 15,
  },
  500: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  992: {
    slidesPerView: 4,
    spaceBetween: 10,
  },
  1200: {
    slidesPerView: 4.3,
    spaceBetween: 10,
  },
};
export default {
  props: {
    item: Object,
  },
  components: {
    Product,
    RightChevIcon,
  },
  data() {
    return {
      id: "specialCategory_",
    };
  },
  mounted() {
    this.id += this.item.id;
    this.initSlider();
  },
  methods: {
    slugGenerator(item) {
      if (item.slug) return item.slug;
      return item.title.replaceAll(" ", "-");
    },
    initSlider(kill = false) {
      this.initSwiper(this.id, kill, () => {
        if (this.slider) {
          this.slider.destroy();
        }
        this.slider = new Swiper(`#${this.id} .swiper-container`, {
          spaceBetween: 1,
          loop: false,
          speed: 500,
          centeredSlides: true,
          centeredSlidesBounds: true,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: `#${this.id} .next-btn-holder`,
            prevEl: `#${this.id} .prev-btn-holder`,
          },
          breakpoints,
        });
        setTimeout(() => {
          this.minHeight = document
            .querySelector(`#${this.id} .swiper-container`)
            .getBoundingClientRect().height;
        }, 1700);
      });
    },
  },
};
</script>
<style>
.productContainer {
  position: relative;
}
section.mainProducts img {
  width: 100%;
}
section.mainProducts {
  width: 85%;
  margin: 24px auto;
}

.prev-btn-holder {
  position: absolute;
  top: 53%;
  right: 100.22%;
  transform: translateY(-50%) rotateY(180deg);
}
.next-btn-holder {
  position: absolute;
  top: 53%;
  left: 100.22%;
  transform: translateY(-50%);
}
.prev-btn-icon {
  position: absolute;
  top: 53%;
  right: 8%;
  transform: translateY(-50%);
}
.next-btn-icon {
  position: absolute;
  top: 53%;
  left: 8%;
  transform: translateY(-50%);
}
</style>
