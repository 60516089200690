var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header',{attrs:{"id":"homeHeader"}}),_c('main',{staticClass:"main"},[_c('section',{staticClass:"container mt-2 mt-xl-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-xl-9"},[_c('HomeSlider')],1),_c('div',{staticClass:"col-12 col-xl-3 mt-5 mt-xl-0 benibox"},[_c('SingleAmazingProduct')],1)])]),_c('div',{staticClass:"container"},[_c('ProductCategories')],1),_c('div',{staticClass:"container"},[(
            _vm.recommendations &&
            _vm.recommendations[1] &&
            _vm.recommendations[1].products &&
            _vm.recommendations[1].products.length
          )?[_c('RecommendationSliders',{attrs:{"data":_vm.recommendations[1]}})]:_vm._e()],2),_c('div',{staticClass:"container banner_one"},[_c('ResponsiveBanner',{staticClass:"w-100",attrs:{"item":_vm.access(_vm.$store.getters, 'front/getHomeData.advertise.center'),"mobile-item":_vm.access(_vm.$store.getters, 'front/getHomeData.advertise.center_mobile')}})],1),_c('RecommendedProductSlider',{staticClass:"mt-2 mt-lg-5"}),_c('div',[_c('QuadrupleBanner')],1),_c('div',{staticClass:"container mt-2 mt-lg-5"},[(
          _vm.recommendations &&
          _vm.recommendations[5] &&
          _vm.recommendations[5].products &&
          _vm.recommendations[5].products.length
        )?[_c('RecommendationSliders',{attrs:{"data":_vm.recommendations[5]}})]:_vm._e()],2),(_vm.advertiseBanners && _vm.advertiseBanners.single_bottomBanner)?_c('banners',{staticClass:"container",attrs:{"banner":_vm.advertiseBanners.single_bottomBanner,"fullWidth":""}}):_vm._e(),_c('div',{staticClass:"container"},[(
          _vm.recommendations &&
          _vm.recommendations[2] &&
          _vm.recommendations[2].products &&
          _vm.recommendations[2].products.length
        )?[_c('RecommendationSliders',{attrs:{"data":_vm.recommendations[2]}})]:_vm._e(),(_vm.advertiseBanners)?_c('HomeBanners',{attrs:{"banner":_vm.advertiseBanners.single_banner_1,"fullWidth":""}}):_vm._e()],2),_c('div',{staticClass:"container mt-2 mt-lg-5"},[(
          _vm.recommendations &&
          _vm.recommendations[6] &&
          _vm.recommendations[6].products &&
          _vm.recommendations[6].products.length
        )?[_c('RecommendationSliders',{attrs:{"data":_vm.recommendations[6]}})]:_vm._e()],2),_c('div',{staticClass:"container"},[(_vm.advertiseBanners)?_c('div',{staticClass:"d-flex align-items-center justify-content-between flex-column flex-md-row mt-2 mt-lg-5"},[_c('HomeBanners',{attrs:{"banner":_vm.advertiseBanners.bottom_right}}),_c('HomeBanners',{staticClass:"mt-4 mt-md-0",attrs:{"banner":_vm.advertiseBanners.bottom_left}})],1):_vm._e(),(
          _vm.recommendations &&
          _vm.recommendations[3] &&
          _vm.recommendations[3].products &&
          _vm.recommendations[3].products.length
        )?[_c('RecommendationSliders',{attrs:{"data":_vm.recommendations[3]}})]:_vm._e()],2),_c('div',[_c('BeforeAfter')],1),(_vm.advertiseBanners)?_c('div',{staticClass:"container d-flex align-items-center justify-content-between flex-column flex-md-row mt-2 mt-lg-5"},[_c('HomeBanners',{attrs:{"banner":_vm.advertiseBanners.twin_banner_4}}),_c('HomeBanners',{staticClass:"mt-4 mt-md-0",attrs:{"banner":_vm.advertiseBanners.twin_banner_5}})],1):_vm._e(),_c('div',{staticClass:"mt-5"},[_c('TrustedCustomers')],1),_c('div',{staticClass:"container mt-5"},[(_vm.advertiseBanners)?_c('HomeBanners',{attrs:{"banner":_vm.advertiseBanners.single_banner_6,"fullWidth":""}}):_vm._e(),(
          _vm.recommendations &&
          _vm.recommendations[4] &&
          _vm.recommendations[4].products &&
          _vm.recommendations[4].products.length
        )?[_c('RecommendationSliders',{attrs:{"data":_vm.recommendations[4]}})]:_vm._e()],2),(
        _vm.$store.getters['front/getHomeData'] != null &&
        _vm.$store.getters['front/getHomeData'].flashes != ''
      )?_c('Flashes',{attrs:{"flashes":_vm.$store.getters['front/getHomeData'].flashes}}):_vm._e(),_c('Weblogs',{staticClass:"mt-5"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }