<template>
  <section class="campaigns pb-3 pt-sm-3 pt-1 mt-2 mt-sm-5">
    <div
      v-for="(flash, index) in flashes"
      :key="flash.id"
      class="container px-0"
      :class="index != 0 ? 'py-5' : ''"
    >
      <!--    <HeaderSection class="px-3" :mode="'flash'" :textBtn="'محصولات بیشتر'" :status="false" :text="flash.title" :route="'/products?flash_id=' + flash.id " />-->
      <div class="campaigns-content overflow-hidden">
        <div
          v-if="!mediaQueries.mobileSize"
          class="campaigns-banner w-100 h-100 position-relative"
        >
          <router-link :to="getUrl(flash)">
            <img loading="lazy" class="w-100" :src="flash.image.url" alt="" />
          </router-link>
          <!--          <router-link :to="getUrl(flash)"-->
          <!--            class="campaigns-content-more-btn py-2 px-3 bg-color-theme text-black-white fontsize-mini">مشاهده-->
          <!--            محصولات</router-link>-->
        </div>
        <div
          @scroll="onScrollStart(index)"
          ref="slider"
          class="campaigns-slide h-100 w-100"
        >
          <router-link
            :to="getUrl(flash)"
            class="slide"
            v-if="mediaQueries.mobileSize"
          >
            <!--            <img v-if="mediaQueries.mobileSize" loading="lazy" :src="flash.image.url" alt="">-->
            <img
              class="w-100"
              loading="lazy"
              :src="flash.mobile_image.url"
              alt=""
            />
          </router-link>
          <div
            class="slide"
            v-for="(product, _index) in getProducts(flash.products)"
          >
            <Product :key="product.id" :product="product" />
          </div>
          <template v-if="mediaQueries.mobileSize">
            <div
              @click="slide(index, 'left')"
              class="arrow-left"
              :class="{ disabled: disabledLeftArrows.includes(index) }"
            >
              <ZArrowBold style="transform: rotate(180deg)" />
            </div>
            <div
              @click="slide(index, 'right')"
              :class="{ disabled: disabledRightArrows.includes(index) }"
              class="arrow-right"
            >
              <ZArrowBold />
            </div>
          </template>

          <router-link
            :to="getUrl(flash)"
            class="floating-more"
            :class="{ show: disabledLeftArrows.includes(index) }"
          >
            دیدن همه
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import HeaderSection from "@/parts/Front/components/HeaderSection";
import Product from "@/parts/Front/components/product/Product";
import Swiper from "swiper/swiper-bundle.min";
import FlashProduct from "@/parts/Front/components/flash/FlashProduct";
import ZArrowBold from "@/parts/Front/components/shared/ZArrowBold";
import Debounce from "lodash/debounce";

export default {
  name: "Flashes",
  props: ["flash", "flashes"],
  components: {
    ZArrowBold,
    FlashProduct,
    HeaderSection,
    Product,
  },
  inject: ["mediaQueries"],
  data() {
    return {
      disabledSliders: [],
      disabledRightArrows: [],
      disabledLeftArrows: [],
      debouncedOnScroll: Debounce(this.onScroll, 50),
    };
  },
  watch: {},
  mounted() {
    this.initFlashProducts();
  },
  methods: {
    getUrl(flash) {
      return "/products?flash_id=" + flash.id;
    },
    slide(index, direction) {
      let gridGap = 25;
      let opposite = direction === "right";
      let slider = (window.slider = this.$refs.slider[index]);
      let children = [...slider.children].filter((el) =>
        el.classList.contains("slide")
      );
      window.children = opposite ? children.reverse() : children;
      let totalScroll = window.slider.scrollWidth - window.slider.offsetWidth;
      let currentScroll = -1 * slider.scrollLeft;
      let currentSlide = window.children[0];
      let temp = opposite ? totalScroll : 0;
      window.children.every((child) => {
        if (opposite) {
          temp -= child.getBoundingClientRect().width + gridGap;
          if (temp < currentScroll) {
            currentSlide = child;
            return false;
          }
        } else {
          temp += child.getBoundingClientRect().width + gridGap;
          if (temp > currentScroll) {
            currentSlide = child;
            return false;
          }
        }
        currentSlide = child;
        return true;
      });
      slider.scrollLeft = opposite
        ? -1 * Math.max(temp, 0)
        : -1 * Math.min(temp, totalScroll);
    },
    getProducts(products) {
      if (window.innerWidth < 768) {
        return [...products].splice(0, Math.min(products.length, 14));
      } else {
        return [...products].splice(0, Math.min(products.length, 6));
      }
    },
    onScrollStart(index) {
      this.disabledRightArrows = this.disabledRightArrows.filter(
        (val) => val != index
      );
      this.disabledLeftArrows = this.disabledLeftArrows.filter(
        (val) => val != index
      );
      this.debouncedOnScroll(index);
    },
    onScroll(index) {
      let slider = this.$refs.slider[index];
      let totalScroll = slider.scrollWidth - slider.offsetWidth;
      // if (totalScroll == 0) {
      //   this.debouncedOnScroll(index)
      // }
      let currentScroll = -1 * slider.scrollLeft;

      if (currentScroll < 20) {
        !this.disabledRightArrows.includes(index) &&
          this.disabledRightArrows.push(index);
      } else {
        this.disabledRightArrows = this.disabledRightArrows.filter(
          (val) => val != index
        );
      }
      if (currentScroll >= totalScroll - 20) {
        !this.disabledLeftArrows.includes(index) &&
          this.disabledLeftArrows.push(index);
      } else {
        this.disabledLeftArrows = this.disabledLeftArrows.filter(
          (val) => val != index
        );
      }
    },
    initFlashProducts(kill = false) {
      this.initSwiper("myProducts", kill, () => {
        if (this.slider) {
          this.slider.destroy();
        }
        this.slider = new Swiper(".campaigns .swiper-container", {
          spaceBetween: 1,
          //   centeredSlides: true,
          loop: false,
          speed: 500,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: ".campaigns .swiper-button-next",
            prevEl: ".campaigns .swiper-button-prev",
          },
          breakpoints: {
            300: {
              slidesPerView: 1,
              spaceBetween: 1,
            },
            500: {
              slidesPerView: 2,
              spaceBetween: 1,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 1,
            },

            // 1200: {
            // slidesPerView: 3,
            // spaceBetween: 0,
            // }
          },
        });
      });
    },
  },
};
</script>
<style scoped>
.arrow-left {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
  transition: all 300ms;
}
.arrow-right {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  transition: all 300ms;
}
.arrow-left.disabled {
  transform: translate(-90px, -50%);
}
.arrow-right.disabled {
  transform: translate(90px, -50%);
}

.disabled {
  opacity: 0.5;
}

.floating-more {
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translate(-50%, 20px);
  border-radius: 4px;
  background: var(--color-theme);
  color: var(--color-theme2);
  padding: 5px 10px;
  opacity: 0;
  transition: 300ms all;
}

.floating-more.show {
  transform: translate(-50%, 0);
  opacity: 1;
}

.slide {
  min-width: 100px;
}
@media (max-width: 580px) {
  .slide {
    min-width: 131px;
  }
}
</style>
<style>
/*.campaigns-content {*/
/*  height: 410px ;*/
/*}*/
/*@media screen and (min-width: 1600px){*/
/*  .campaigns-content {*/
/*    height: 550px ;*/
/*  }*/
/*}*/
.campaigns {
  height: 100%;
}

.campaigns-slide {
  display: grid;
  padding: 30px;
  grid-gap: 25px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.arrow-right,
.arrow-left {
  background: var(--color-theme);
  height: 38px;
  width: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
