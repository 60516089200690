<template>
  <div
    id="ProductQuestion"
    class="tab-pane fade pb-4 in-ProductQuestion"
    :class="[activeTab === 'productQuestion' ? 'in active show' : '']"
  >
    <div class="d-sm-flex justify-content-center pt-3 px-3  pb-4 ">
      <div class=" justify-content-around mb-0">
        <h6
          v-if="ProductQuestion != null && ProductQuestion.length != 0"
          class="text-color-444 mb-4 fontsize-medium"
        >
          پرسش خود را درباره این کالا از ما بپرسید.
        </h6>
        <template v-if="hideForm">
          <span v-if="ProductQuestion != null && ProductQuestion.length == 0">
            <h6
              class="fontsize-small text-color-666 text-center line-height2 mb-2 "
            >
              پرسشی برای این محصول ثبت نشده است
            </h6>
          </span>
          <button
            type="button"
            @click="hideForm = false"
            class="send-question-btn fontsize-small text-light py-2 px-5 bg-color-theme d-block mx-auto border-r-25"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2 12.015C2 6.74712 6.21 2 12.02 2C17.7 2 22 6.65699 22 11.985C22 18.1642 16.96 22 12 22C10.36 22 8.54 21.5593 7.08 20.698C6.57 20.3876 6.14 20.1572 5.59 20.3375L3.57 20.9384C3.06 21.0986 2.6 20.698 2.75 20.1572L3.42 17.9139C3.53 17.6034 3.51 17.2729 3.35 17.0125C2.49 15.4301 2 13.6975 2 12.015ZM10.7 12.015C10.7 12.7261 11.27 13.2969 11.98 13.307C12.69 13.307 13.26 12.7261 13.26 12.025C13.26 11.314 12.69 10.7431 11.98 10.7431C11.28 10.7331 10.7 11.314 10.7 12.015ZM15.31 12.025C15.31 12.7261 15.88 13.307 16.59 13.307C17.3 13.307 17.87 12.7261 17.87 12.025C17.87 11.314 17.3 10.7431 16.59 10.7431C15.88 10.7431 15.31 11.314 15.31 12.025ZM7.37 13.307C6.67 13.307 6.09 12.7261 6.09 12.025C6.09 11.314 6.66 10.7431 7.37 10.7431C8.08 10.7431 8.65 11.314 8.65 12.025C8.65 12.7261 8.08 13.2969 7.37 13.307Z"
                fill="currentColor"
              />
            </svg>
            برای ثبت پرسش کلیک کنید
          </button>
        </template>
      </div>
    </div>
    <section class="response-register box-shaddow20 mb-3" v-if="!hideForm">
      <form
        id="questionForm"
        class="fontsize13 p-4"
        @submit.prevent="ProductQuestionSubmit()"
      >
        <label for="">پرسش خود را در کادر زیر وارد کنید:</label>
        <textarea
          class="rounded p-2 mb-2 w-100 fontsize-mini box-shaddow20"
          v-model="question"
          name=""
          rows="5"
          placeholder="پرسش شما *"
        ></textarea>

        <button
          type="submit"
          :class="{ disabled: disabled == true }"
          class="py-2 px-md-5 px-3 bg-color-theme text-black-white fontsize-small border-r-25"
        >
          ثبت پرسش
        </button>
      </form>
    </section>
    <Loading v-if="ProductQuestion == null" />
    <div
      v-if="ProductQuestion != ''"
      class="user-ProductQuestion px-sm-3 px-2 py-sm-2 pt-4"
    >
      <div
        v-for="(q, index) in ProductQuestion"
        :key="q.id"
        :id="`question${q.id}`"
        class="user-ProductQuestion-item pb-3 mb-3"
      >
        <div
          class="d-flex flex-wrap align-items-baseline justify-content-between mb-2"
        >
          <span class="text-color-999 fontsize-mini mr-2">
            {{ moment(q.created_at).format("dddd DD MMMM YYYY") }}
          </span>
        </div>
        <p
          class="text-color-666 fontsize-small text-justify gap24 line-height2 border-bottom pb-2 questionRow"
        >
          <span class="fontsize-medium font-weight-bold">
            <svg
              height="18"
              width="18"
              version="1.1"
              id="_x32_"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 512 512"
              xml:space="preserve"
            >
              <g>
                <path
                  class="st0"
                  fill="#00a6ff"
                  d="M256,0C114.616,0,0,114.612,0,256s114.616,256,256,256s256-114.612,256-256S397.385,0,256,0z M207.678,378.794
c0-17.612,14.281-31.893,31.893-31.893c17.599,0,31.88,14.281,31.88,31.893c0,17.595-14.281,31.884-31.88,31.884
C221.959,410.678,207.678,396.389,207.678,378.794z M343.625,218.852c-3.596,9.793-8.802,18.289-14.695,25.356
c-11.847,14.148-25.888,22.718-37.442,29.041c-7.719,4.174-14.533,7.389-18.769,9.769c-2.905,1.604-4.479,2.95-5.256,3.826
c-0.768,0.926-1.029,1.306-1.496,2.826c-0.273,1.009-0.558,2.612-0.558,5.091c0,6.868,0,12.512,0,12.512
c0,6.472-5.248,11.728-11.723,11.728h-28.252c-6.475,0-11.732-5.256-11.732-11.728c0,0,0-5.645,0-12.512
c0-6.438,0.752-12.744,2.405-18.777c1.636-6.008,4.215-11.718,7.508-16.694c6.599-10.083,15.542-16.802,23.984-21.48
c7.401-4.074,14.723-7.455,21.516-11.281c6.789-3.793,12.843-7.91,17.302-12.372c2.988-2.975,5.31-6.05,7.087-9.52
c2.335-4.628,3.955-10.067,3.992-18.389c0.012-2.463-0.698-5.702-2.632-9.405c-1.926-3.686-5.066-7.694-9.264-11.29
c-8.45-7.248-20.843-12.545-35.054-12.521c-16.285,0.058-27.186,3.876-35.587,8.62c-8.36,4.776-11.029,9.595-11.029,9.595
c-4.268,3.718-10.603,3.85-15.025,0.314l-21.71-17.397c-2.719-2.173-4.322-5.438-4.396-8.926c-0.063-3.479,1.425-6.81,4.061-9.099
c0,0,6.765-10.43,22.451-19.38c15.62-8.992,36.322-15.488,61.236-15.429c20.215,0,38.839,5.562,54.268,14.661
c15.434,9.148,27.897,21.744,35.851,36.876c5.281,10.074,8.525,21.43,8.533,33.38C349.211,198.042,347.248,209.058,343.625,218.852
z"
                />
              </g>
            </svg>
            پرسش :</span
          >
          <span>{{ q.body }}</span>
          <button class="replayButton" @click="replayQuestion(q)">
            پاسخ
          </button>
        </p>
        <p
          v-for="(ans, index) in q.answers"
          :key="index"
          class="d-flex answerBox text-color-666 fontsize-small text-justify gap24 line-height2"
        >
          <span class="fontsize-medium font-weight-bold">
            <svg
              fill="#00A3A7"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 52 52"
              enable-background="new 0 0 52 52"
              xml:space="preserve"
            >
              <path
                d="M26,4C12.7,4,2,13.8,2,26c0,3.9,1.1,7.5,3,10.8c0.2,0.4,0.3,0.9,0.2,1.3L3,45c-0.4,1.3,0.8,2.4,2.1,2l7-2.4
    c0.5-0.2,1-0.1,1.4,0.2c3.7,2.1,8,3.3,12.6,3.3c13.3,0,24-9.8,24-22C49.9,13.8,39.2,4,26,4L26,4L26,4z M37.5,21.1l-12.2,12
    c-0.5,0.5-1,0.7-1.7,0.7c-0.6,0-1.2-0.2-1.7-0.7L16,27.3c-0.5-0.5-0.5-1.2,0-1.6l1.7-1.6c0.5-0.5,1.2-0.5,1.7,0l4.2,4.2l10.5-10.4
    c0.5-0.5,1.2-0.5,1.7,0l1.7,1.6C37.9,19.9,37.9,20.7,37.5,21.1L37.5,21.1L37.5,21.1z"
              />
            </svg>
            پاسخ :</span
          >
          <span>{{ ans.body }}</span>
        </p>
      </div>
    </div>
    <portal to="productQuestionModalTarget">
      <Transition>
        <div id="replayBox" @click="closeReplayBox" v-show="replayForm">
          <p v-if="questionTarget">
            {{ questionTarget.body }}
          </p>
          <form @submit.prevent="sendAnswer" id="replayForm">
            <textarea
              name=""
              v-model="answer"
              placeholder="لطفا پاسخ خود را وارد کنید :"
            ></textarea>
            <input
              :class="{ disabled: disabled == true }"
              type="submit"
              value="ثبت پاسخ"
            />
          </form>
        </div>
      </Transition>
    </portal>
  </div>
</template>
<script>
import { BFormRating, BFormCheckbox } from "bootstrap-vue";

import Loading from "@/parts/Front/components/shared/Loading";
import { throws } from "assert";
export default {
  props: ["activeTab", "product"],
  components: {
    Loading,
    BFormRating,
    BFormCheckbox,
  },
  data() {
    return {
      question: "",
      hideForm: true,
      disabled: false,
      ProductQuestion: null,
      questionTarget: "",
      getProductQuestionStatus: false,
      replayForm: false,
      answer: null,
    };
  },
  watch: {
    activeTab(newActiveTab) {
      if (newActiveTab === "productQuestion") {
        this.getProductQuestion();
      }
    },
  },
  mounted() {
    if (this.activeTab === "productQuestion") {
      this.getProductQuestion();
    }
  },
  methods: {
    sendAnswer() {
      this.ProductQuestionSubmit(this.questionTarget, this.answer, () => {
        this.answer = null;
        this.closeReplayBox();
      });
    },
    closeReplayBox(e) {
      if (!e || e.target.id == "replayBox") {
        this.replayForm = false;
        document.getElementById("mainDiv").classList.remove("blurPage");
      }
    },
    replayQuestion(question) {
      this.questionTarget = question;
      document
        .getElementById(`question${question.id}`)
        .scrollIntoView({ behavior: "smooth" });
      document.getElementById("mainDiv").classList.add("blurPage");

      this.replayForm = true;
    },
    getProductQuestion() {
      if (this.ProductQuestion == null && !this.getProductQuestionStatus) {
        this.getProductQuestionStatus = true;
        this.$axios
          .get(`front/product-question/${this.$route.params.id}`)
          .then((response) => {
            this.ProductQuestion = response.data.data.question.reverse();
          });
      }
    },
    async ProductQuestionSubmit(
      question = null,
      answer = null,
      callBack = null
    ) {
      if (this.$store.getters["front/getLoginStatus"]) {
        this.disabled = true;
        try {
          let fd = new FormData();
          fd.append("body", answer ? answer : this.question);
          fd.append("product_id", this.$route.params.id);
          fd.append("parent_id", question ? question.id : null);
          const response = await this.$axios.post(
            "customer/product-question",
            fd
          );
          if (callBack) {
            callBack();
          }
          this.hideForm = true;
          this.$root.notify(response.data.message, "success");
          this.question = null;
        } catch (error) {
          this.$root.notify(error);
        } finally {
          this.disabled = false;
        }
      } else {
        this.$root.notify("برای ثبت پرسش ابتدا وارد سایت شوید.", "warning");
      }
    },
  },
};
</script>
<style scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
.response-register form textarea {
  height: unset !important;
}
</style>
<style>
#ProductQuestion .form-control:focus {
  box-shadow: unset !important;
}

#ProductQuestion .form-control-lg {
  height: unset !important;
}

#ProductQuestion .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--color-theme);
  background-color: var(--color-theme);
}

#ProductQuestion
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: var(--color-theme) !important;
}

#ProductQuestion .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgb(183, 149, 111, 0.5);
}

#ProductQuestion .custom-control-label::before {
  top: 0;
}

#ProductQuestion .custom-control-label::after {
  top: 0;
}

#ProductQuestion .flex-grow-1 {
  flex-grow: unset !important;
}

@media screen and (max-width: 768px) {
  .send-question-btn {
    display: block;
    margin: auto;
  }
}
form#questionForm button {
  color: white;
  align-self: flex-start;
}
form#questionForm {
  display: flex;
  flex-direction: column;
}
form#questionForm textarea {
  border: 1px solid #1213;
  resize: none;
  width: 100%;
  /* width: min(500px, 100%) !important; */
  /* box-shadow: 0 0 4px #121314; */
  font-size: 16px;
}
.gap24 {
  gap: 24px;
}
.questionRow button {
  background: var(--color-border);
  color: var(--text-color-grey);
  border-radius: 5px;
  align-self: flex-start;
  font-weight: 900;
}
.questionRow {
  display: grid;
  gap: 10px;
  grid-template-columns: 80px 1fr 80px;
}
@media (max-width:768px){
  .questionRow{
  grid-template-columns: 1fr ;

  }
}
div#replayBox {
  position: fixed;
  inset: 0;
  background: #00000040;
  z-index: 1000000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}
div#replayBox p {
  width: max(320px, 50%);
  color: black;
  background: white;
  padding: 8px 16px;
  min-height: 60px;
  align-self: flex-start;
  margin-right: 14px;
  border-radius: 12px 4px;
  font-weight: 600;
}
div#replayBox #replayForm {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  width: max(300px, 40%);
}
form#replayForm input {
  align-self: baseline;
  padding: 8px 16px;
  background: var(--color-theme);
  color: white;
}

form#replayForm textarea {
  width: 100%;
  resize: none;
  height: 100px;
  padding: 8px;
  border-radius: 4px 12px;
}
div#replayBox #replayForm {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: max(300px, 40%);
}
.answerBox {
  margin-right: 40px;
  display: grid !important;
}
</style>
