<template>
  <section id="QuadrupleBanner" class="container">
    <div v-if="customeBanners">
      <div class="grid_banner">
            <figure class="QuadrupleBannerFig" v-if="customeBanners.first_centerBanner">
                <DynamicLinker  :item="customeBanners.first_centerBanner">
                  <img :src="customeBanners.first_centerBanner.picture" alt="" />
                </DynamicLinker>
              </figure>
              <figure class="QuadrupleBannerFig" v-if="customeBanners.second_centerBanner">
                <DynamicLinker :item="customeBanners.second_centerBanner">
                  <img :src="customeBanners.second_centerBanner.picture" alt="" />
                </DynamicLinker>
              </figure>
              <figure class="QuadrupleBannerFig" v-if="customeBanners.third_centerBanner">
                <DynamicLinker  :item="customeBanners.third_centerBanner">
                  <img :src="customeBanners.third_centerBanner.picture" alt="" />
                </DynamicLinker>
              </figure>
              <figure class="QuadrupleBannerFig" v-if="customeBanners.fourth_centerBanner">
                <DynamicLinker  :item="customeBanners.fourth_centerBanner">
                  <img :src="customeBanners.fourth_centerBanner.picture" alt="" />
                </DynamicLinker>
              </figure>
        </div>
      <div class="swiper sliderBanner">
        <div class="QuadrupleSlidersBanner-content">
          <div class="QuadrupleSlidersBanner-content-item position-relative">
            <div
              class="swiper-container"
              v-if="!disabledSliders.includes('QuadrupleBannerSlider')"
            >
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-if="customeBanners.first_bannerMobile"
                >
                  <figure class="QuadrupleBannerFig">
                    <DynamicLinker :item="customeBanners.first_bannerMobile">
                      <img
                        :src="customeBanners.first_bannerMobile.picture"
                        alt=""
                      />
                    </DynamicLinker>
                  </figure>
                </div>
                <div
                  class="swiper-slide"
                  v-if="customeBanners.second_bannerMobile"
                >
                  <figure class="QuadrupleBannerFig">
                    <DynamicLinker :item="customeBanners.second_bannerMobile">
                      <img
                        :src="customeBanners.second_bannerMobile.picture"
                        alt=""
                      />
                    </DynamicLinker>
                  </figure>
                </div>
                <div
                  class="swiper-slide"
                  v-if="customeBanners.third_bannerMobile"
                >
                  <figure class="QuadrupleBannerFig">
                    <DynamicLinker :item="customeBanners.third_bannerMobile">
                      <img
                        :src="customeBanners.third_bannerMobile.picture"
                        alt=""
                      />
                    </DynamicLinker>
                  </figure>
                </div>
                <div
                  class="swiper-slide"
                  v-if="customeBanners.fourth_bannerMobile"
                >
                  <figure class="QuadrupleBannerFig">
                    <DynamicLinker :item="customeBanners.fourth_bannerMobile">
                      <img
                        :src="customeBanners.fourth_bannerMobile.picture"
                        alt=""
                      />
                    </DynamicLinker>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Swiper from "swiper/swiper-bundle.min";
import Product from "@/parts/Front/components/product/Product";
import DynamicLinker from "@/parts/Front/components/shared/DynamicLinker.vue";
let breakpoints = {
  300: {
    centeredSlides: true,
          centeredSlidesBounds: true,
    slidesPerView: 1.2,
    spaceBetween: 10,
  },
  500: {
    centeredSlides: true,
          centeredSlidesBounds: true,
    slidesPerView: 2.5,
    spaceBetween: 10,
  },
  992: {
    centeredSlides: false,
          centeredSlidesBounds: false,
    slidesPerView: 4,
    spaceBetween: 10,
  },
  1200: {
    centeredSlides: false,
          centeredSlidesBounds: false,
    slidesPerView: 4,
    spaceBetween: 10,
  },
};
export default {
  name: "QuadrupleBannerSlider",
  components: { Product, DynamicLinker },
  inject: ["mediaQueries"],
  data() {
    return {
      slider: null,
    };
  },
  watch: {
    customeBanners: {
      immediate: true,
      handler(customeBanners) {
        if (customeBanners == null) {
          return;
        }
        this.initSlider(true);
      },
    },
  },
  mounted() {
    if (this.$store.getters["front/getHomeData"] != null) this.initSlider(true);
  },
  computed: {
    //   products() {
    //     return this.readyProducts;
    //   },
    customeBanners() {
      if (this.$store.getters["front/getHomeData"] != null) {
        let advertise = this.$store.getters["front/getHomeData"].advertise;
        return advertise;
      } else {
        return null;
      }
    },
  },
  methods: {
    initSlider(kill = false) {
      this.initSwiper("QuadrupleBannerSlider", kill, () => {
        if (this.slider) {
          this.slider.destroy();
        }
        this.slider = new Swiper("#QuadrupleBanner .swiper-container", {
          loop: true,
          speed: 500,
        
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },

          breakpoints,
        });
      });
    },
  },
};
</script>

<style scoped>
#QuadrupleBanner {
  margin-top: 8rem;
}
#QuadrupleBanner .grid_banner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}
.sliderBanner {
    width: 100%;
    display: none;
}
.QuadrupleBannerFig,
.QuadrupleBannerFig img {
  margin: 0;
  width: 100%;
}
@media screen and (max-width: 600px) {
  #QuadrupleBanner .grid_banner {
    display: none;
  }
  .sliderBanner {
    display: inline-block;
  }
}
</style>
