<template>
  <div class="swiper" id="beni">
    <div :style="`min-height: ${minHeight}px`">
      <div class="swiper-container h-100">
        <div v-if="sliders" class="swiper-wrapper ">
          <article class="swiper-slide firstSlide showOnMobile position-relative">
              <h3 >بنی باکس</h3>
              <img src="@/assets/new-beni-box.gif" alt="بنی باکس" />
              <div class="countdown">
                <CountDownTimer  :deadline="sliders[0].discount_until" />
              </div>
          </article>
          <template v-if="mobile">
            <ProductMobile
              class="swiper-slide"
              v-for="(product, index) in sliders"
              :key="index + 'index'"
              :product="product"
            />
          </template>

          <template v-else>
            <Product
              class="swiper-slide"
              v-for="(product, index) in sliders"
              :key="index"
              :product="product"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Product from "@/parts/Front/components/product/CountDownProduct.vue";
import ProductMobile from "@/parts/Front/components/product/Product";

import Swiper from "swiper/swiper-bundle.min.js";
import CountDownTimer from "@/parts/Front/components/shared/CountDownTimer.vue";
let breakpoints = {
  300: {
    slidesPerView: 1.8,
    spaceBetween: 15,
  },
  400: {
    slidesPerView:2.15,
    spaceBetween: 10,
  },
  768: {
    slidesPerView:2,
    spaceBetween: 10,
  },
  992: {
    slidesPerView: 1,
    spaceBetween: 10,
  },

};
export default {
  name: "SingleAmazingProduct",
  components: {
    Product,
    CountDownTimer,
    ProductMobile,
  },
  data() {
    return {
      swiper: null,
      readyProducts: null,
      mobile: false,
      minHeight: "unset",
    };
  },
  watch: {
    sliders() {
      // پاک کردن سویپر برای نمایش درست اسلایدر
      this.initHeaderSwiper(true);
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setMobileStatus);
  },
  mounted() {
    this.setMobileStatus();
    window.addEventListener("resize", this.setMobileStatus);
    this.setReadyProducts();
    this.initHeaderSwiper();
  },
  computed: {
    sliders() {
      return this.readyProducts;
    },
  },
  methods: {
    setMobileStatus() {
      if (window.innerWidth < 768) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    },
    async setReadyProducts() {
      this.readyProducts = null;
      let res = await this.$store.dispatch(
        "front/getIndexPageContent",
        "most-timed-discounts"
      );
      this.readyProducts = res;
    },
    initHeaderSwiper(kill = false) {
      this.initSwiper("header-swiper", kill, () => {
        try {
          if (this.slider) {
            this.slider.destroy();
          }
        } catch (e) {}
        this.slider = new Swiper("#beni .swiper-container", {
          spaceBetween: 10,
          speed: 1000,
          loop: false,
          autoplay: false,
          breakpoints,
          pagination: {
            el: ".swiper .swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".swiper .swiper-button-next",
            prevEl: ".swiper .swiper-button-prev",
          },
        });
        setTimeout(() => {
          this.minHeight = document
            .querySelector("#beni .swiper-container")
            .getBoundingClientRect().height;
        }, 1700);
      });
    },
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
    }
  },
};
</script>
<style>
.showOnMobile {
  display: none;
}
@media (max-width: 768px) {
  .hideOnMobile {
    display: none;
  }

  .showOnMobile {
    display: flex !important;
  }
 .firstSlide {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 8px;
   position: relative;
   width: 35% !important;
 }
 .firstSlide h3 {
   color: white;
   font-size: 22px;
   transform: translateY(70%);
 }

 .firstSlide .flip-card {
   border-radius: 2px;
   position: relative;
   right: 5px;
 }
 .firstSlide img {
   width: 110%;
   transform: translateY(12%);
 }

}
@media (max-width: 400px) {
  .firstSlide img {
    width: 120%;
    transform: translateY(15%);
  }
  .firstSlide h3 {
    color: white;
    font-size: 22px;
    transform: translateY(80%);
  }
  .countdown{
   transform: translateY(20%);
  }
}
</style>
<style>
@media (max-width: 380px) {

.flip-card{
    width: 23px!important;
  b{
    font-size: 14px;
  }
  }
}
</style>
