<template>
  <div>
    <healthCare
      @closeHealthModal="showModalSkinHair = false"
      v-if="showModalSkinHair"
      ref="healthCare"
    ></healthCare>

    <WhatsappContact />
      <div id="mainDiv">
        <div v-if="( !mediaQueries.large || $route.name !== 'cart') && $route.path != '/magazine' &&  !$route.path.includes('/games/exam')  && !$route.path.includes('/wheel-of-fortune') " class="w-100">
         <router-link  class="d-inline-block w-100 cursor-pointer" to="/club-detail">
            <img class="w-100 d-none d-md-block" src="@/assets/images/advertise.gif" alt="advertise gif">
            <img class="w-100 d-block d-md-none" src="@/assets/images/advertiseMobile.gif" alt="advertise gif">
         </router-link>
        </div>
      <Header v-if="$route.path != '/' && !$route.path.includes('question-landing') &&  $route.path != '/magazine' &&  !$route.path.includes('/games/exam') && !$route.path.includes('/wheel-of-fortune') " />
      <router-view :key="$route.path"></router-view>
      <Footer v-if="!$route.path.includes('question-landing')  &&  $route.path != '/magazine' &&  !$route.path.includes('/games/exam') && !$route.path.includes('/wheel-of-fortune') " />
      <!-- <FooterButtons v-if="!$route.path.includes('question-landing')"></FooterButtons> -->
    </div>
    <portal-target name="productQuestionModalTarget" id="productQuestionModalTarget"></portal-target>
    <div id="mainSpinner" v-show="status">
      <Loading />

      <!-- <img src="@/assets/images/loader.gif" alt="loader" /> -->
    </div>
    <Compare @entered="showCompareContent = true" />
    <transition name="fade">
      <CompareContent
        @left="showCompareContent = false"
        v-if="
          !mediaQueries.mobileSize &&
            Boolean(compares.length) &&
            showCompareContent
        "
      />
    </transition>
  </div>
</template>
<script>
import "bootstrap";
import Header from "@/parts/Front/components/shared/HeaderSecond";
import Footer from "@/parts/Front/components/shared/Footer";
// import FooterButtons from "@/parts/Front/components/FooterButtons";
import Compare from "@/parts/Front/components/shared/Compare";
import CompareContent from "@/parts/Front/components/shared/CompareContent";
import WhatsappContact from "../components/WhatsappContact";
import healthCare from "@/parts/Front/components/healthCare/HealthCare.vue";
import Loading from '@/parts/Front/components/shared/Loading'

//   در هر کامپوننت میتوانیم از این متغییر استفاده کنیم نحوه استفاده در کامپوننت زیر
// RespLoginModal
export default {
  name: "Home",
  components: {
    WhatsappContact,
    Header,
    healthCare,
    Footer,
    // FooterButtons,
    Compare,
    Loading,
    CompareContent,
  },
  inject: ["mediaQueries"],
  data() {
    return {
      showModalSkinHair: false,

      showCompareContent: false,
    };
  },
  mounted() {
    // this.checkHealthModal();
  },
  computed: {
    status() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
    compares() {
      return this.$store.getters["front/getCompares"];
    },
  },
  methods: {
    checkHelthCookie() {
      let count = this.$root.getCookie("healthModalCounter")
        ? Number(this.$root.getCookie("healthModalCounter"))
        : 0;
      return isNaN(count) ? true : count < 4 ? true : false;
    },
    checkHealthModal(callFromMenu = true) {
      if (this.$route.fullPath.includes("#healthCare")) {
        if (this.checkHelthCookie() || callFromMenu) {
          this.showModalSkinHair = true;
        }
      } else if (!callFromMenu) {
        if (this.checkHelthCookie()) {
          this.showModalSkinHair = true;
        }
      }
    },
  },
  watch: {
    // $route: {
    //   handler() {
    //     this.checkHealthModal();
    //   },
    //   deep: true,
    // },
  },
  created() {
    //       let count =this.$root.getCookie("healthModalCounter")
    //     ? Number(this.$root.getCookie("healthModalCounter"))
    //     : 0;
    //  this.$root.setCookie("healthModalCounter", isNaN(count) ? 1 : ++count,);
    //   this.checkHealthModal(false);
    this.$store.dispatch("front/getHomeDataFromServer", {
      $root: this.$root,
    });
  },
};
</script>
<style scoped>
.blurPage{
  overflow: hidden;
  filter: blur(2px);
}
/* اسپینر استایل */
#mainSpinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999999;
  background: rgba(138, 114, 114, 0.72);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#mainSpinner img {
  max-width: 15%;
}
@media (max-width: 768px) {
  #mainSpinner img {
    max-width: 50%;
  }
}
.flower-spinner,
.flower-spinner * {
  box-sizing: border-box;
}

.flower-spinner {
  height: 70px;
  width: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flower-spinner .dots-container {
  height: calc(70px / 7);
  width: calc(70px / 7);
}

.flower-spinner .smaller-dot {
  background: var(--color-theme);
  height: 100%;
  width: 100%;
  border-radius: 50%;
  animation: flower-spinner-smaller-dot-animation 2.5s 0s infinite both;
}

.flower-spinner .bigger-dot {
  background: var(--color-theme);
  height: 100%;
  width: 100%;
  padding: 10%;
  border-radius: 50%;
  animation: flower-spinner-bigger-dot-animation 2.5s 0s infinite both;
}

@keyframes flower-spinner-bigger-dot-animation {
  0%,
  100% {
    box-shadow: var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px,
      var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px,
      var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px,
      var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px;
  }

  50% {
    transform: rotate(180deg);
  }

  25%,
  75% {
    box-shadow: var(--color-theme) 26px 0px 0px,
      var(--color-theme) -26px 0px 0px, var(--color-theme) 0px 26px 0px,
      var(--color-theme) 0px -26px 0px, var(--color-theme) 19px -19px 0px,
      var(--color-theme) 19px 19px 0px, var(--color-theme) -19px -19px 0px,
      var(--color-theme) -19px 19px 0px;
  }

  100% {
    transform: rotate(360deg);
    box-shadow: var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px,
      var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px,
      var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px,
      var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px;
  }
}

@keyframes flower-spinner-smaller-dot-animation {
  0%,
  100% {
    box-shadow: var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px,
      var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px,
      var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px,
      var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px;
  }

  25%,
  75% {
    box-shadow: var(--color-theme) 14px 0px 0px,
      var(--color-theme) -14px 0px 0px, var(--color-theme) 0px 14px 0px,
      var(--color-theme) 0px -14px 0px, var(--color-theme) 10px -10px 0px,
      var(--color-theme) 10px 10px 0px, var(--color-theme) -10px -10px 0px,
      var(--color-theme) -10px 10px 0px;
  }

  100% {
    box-shadow: var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px,
      var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px,
      var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px,
      var(--color-theme) 0px 0px 0px, var(--color-theme) 0px 0px 0px;
  }
}
</style>
