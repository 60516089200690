<template>
  <div id="clubExchange">
    <div class="pr-2 py-1 club-title">
      <h3 class="d-inline-block text-color-grey fontsize-medium m-0">
        تبدیل بن باشگاه
        <span style="font-size: 14px;">(این فرآیند 24 الی 48 ساعت زمان میبرد)</span>
      </h3>
    </div>
    <div v-if="clubData" class="h-100 w-100 mt-4">
      <div class="exchange-container">
        <div class="d-flex align-items-center w-100">
          <p class="m-0 w-s-nowrap col-5">تعداد بن موجود :</p>
          <input
            class="border border-r-10 p-2 col-6"
            type="number"
            readonly
            disabled
            v-model="value.customers_club_bon"
          />
        </div>
        <div class="d-flex align-items-center w-100">
          <p class="m-0 w-s-nowrap col-5">ارزش هر بن :</p>
          <input
            class="border border-r-10 p-2 col-6"
            type="number"
            readonly
            disabled
            v-model="value.bon_value"
          />
        </div>
        <div class="d-flex align-items-center w-100">
          <p class="m-0 w-s-nowrap col-5">امتیاز موجود :</p>
          <input
            class="border border-r-10 p-2 col-6"
            type="number"
            readonly
            disabled
            v-model="value.customers_club_score"
          />
        </div>
        <div class="d-flex align-items-center w-100">
          <p class="m-0 w-s-nowrap col-5">تعداد بن جهت تبدیل :</p>
          <input
            class="border border-r-10 p-2 col-6"
            type="number"
            v-model="changedBonCount"
            autofocus
          />
        </div>
      </div>
      <div class="d-flex justify-content-end mt-4 px-5">
        <button
          :disabled="disabled"
          @click="submitChange"
          class="btn btn-exchange-color"
        >
          تبدیل
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import ChevronLeft from "@/parts/Front/components/Icon/ChevronLeft.vue";

export default {
  name: "ClubExchange",
  props: ["clubData", "clubColor"],
  components: {
    ChevronLeft,
  },
  watch: {
    clubData(newValue) {
      this.value = newValue;
    },
  },
  data() {
    return {
      disabled: false,
      value: this.clubData,
      changedBonCount: 0,
    };
  },
  methods: {
    async submitChange() {
      this.disabled = true;
      await this.$axios
        .post(
          `customer/customers_club/send_bon_convert_request?bon=${this.changedBonCount}`
        )
        .then((res) => {
          this.$root.notify(res.data.message, "success");
        })
        .catch((err) => {
          this.$root.notify(err);
        })
        .finally(() => {
          this.disabled = false;
        });
    },
  },
};
</script>
<style scoped>
#clubExchange .club-title {
  border-right-width: 3px;
  border-right-style: solid;
  border-right-color: v-bind(clubColor);
}
#clubExchange .exchange-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1rem;
}
#clubExchange .btn-exchange-color {
  background: v-bind(clubColor);
}
@media screen and (max-width: 992px) {
  #clubExchange .exchange-container {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
}
}
</style>
