<template>
  <div class="in-product-detail">
    <main class="main container product-details-main">
      <PagesPath
        :pathTitle1="'محصولات'"
        :pathTo1="'/products'"
        :pathTitle2="product != null ? product.title : ''"
      ></PagesPath>

      <ProductContent
        v-if="product && !product.isPreview"
        class="white-bg mt-4"
        :comparable="true"
        v-model="product"
      />
      <SimilarProducts
        v-if="product && !product.isPreview"
        :related="relatedProducts"
      />
      <section class="product-specifications-comments">
        <div v-if="product != null && !product.isPreview" class="tab-content">
          <div
            class="white-bg mt-4"
            id="productSpecifications"
            v-if="product != null && product.specifications != ''"
          >
            <ul
              class="nav nav-tabs border-bottom padding-t-15 padding-b-15 gap-10"
            >
              <li>
                <a
                  class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small active"
                  @click="scrollToTab('productSpecifications')"
                  >مشخصات</a
                >
              </li>
              <li v-if="product != null && product.description != null">
                <a
                  class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small"
                  @click="scrollToTab('productReview')"
                  >نقد و بررسی</a
                >
              </li>

              <li>
                <a
                  class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small"
                  @click="scrollToTab('productComments')"
                  >نظرات کاربران</a
                >
              </li>
              <li>
                <a
                  class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small"
                  @click="scrollToTab('productQuestion')"
                  >پرسش و پاسخ</a
                >
              </li>
            </ul>
            <ProductSpecifications
              class="mt-4"
              :activeTab="'specifications'"
              :product="product"
            />
          </div>
          <div
            class="white-bg mt-4"
            id="productReview"
            v-if="product != null && product.description != null"
          >
            <ul
              class="nav nav-tabs border-bottom padding-t-15 padding-b-15 gap-10"
            >
              <li v-if="product != null && product.specifications != ''">
                <a
                  class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small"
                  @click="scrollToTab('productSpecifications')"
                  >مشخصات</a
                >
              </li>
              <li>
                <a
                  class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small active"
                  @click="scrollToTab('productReview')"
                  >نقد و بررسی</a
                >
              </li>
              <li>
                <a
                  class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small"
                  @click="scrollToTab('productComments')"
                  >نظرات کاربران</a
                >
              </li>
              <li>
                <a
                  class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small"
                  @click="scrollToTab('productQuestion')"
                  >پرسش و پاسخ</a
                >
              </li>
            </ul>
            <ProductReview :activeTab="'review'" :product="product" />
          </div>

          <div class="white-bg mt-4" id="productComments">
            <ul
              class="nav nav-tabs border-bottom padding-t-15 padding-b-15 gap-10"
            >
              <li v-if="product != null && product.specifications != ''">
                <a
                  class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small"
                  @click="scrollToTab('productSpecifications')"
                  >مشخصات</a
                >
              </li>
              <li v-if="product != null && product.description != null">
                <a
                  class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small"
                  @click="scrollToTab('productReview')"
                  >نقد و بررسی</a
                >
              </li>
              <li>
                <a
                  class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small active"
                  @click="scrollToTab('productComments')"
                  >نظرات کاربران</a
                >
              </li>
              <li>
                <a
                  class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small"
                  @click="scrollToTab('productQuestion')"
                  >پرسش و پاسخ</a
                >
              </li>
            </ul>
            <ProductComments :activeTab="'comments'" :product="product" />
          </div>
          <div class="white-bg mt-4" id="productQuestion">
            <ul
              class="nav nav-tabs border-bottom padding-t-15 padding-b-15 gap-10"
            >
              <li v-if="product != null && product.specifications != ''">
                <a
                  class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small"
                  @click="scrollToTab('productSpecifications')"
                  >مشخصات</a
                >
              </li>
              <li v-if="product != null && product.description != null">
                <a
                  class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small"
                  @click="scrollToTab('productReview')"
                  >نقد و بررسی</a
                >
              </li>

              <li>
                <a
                  class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small"
                  @click="scrollToTab('productComments')"
                  >نظرات کاربران</a
                >
              </li>
              <li>
                <a
                  class="border-r-25 padding-t-5 padding-b-5 padding-r-10 padding-l-10 text-color-444 fontsize-small active"
                  @click="scrollToTab('productQuestion')"
                  >پرسش و پاسخ</a
                >
              </li>
            </ul>
            <ProductQuestion
              :activeTab="'productQuestion'"
              :product="product"
            />
          </div>
        </div>
        <div v-else class="tab-content mt-5">
          <div id="review" class="tab-pane fade in active show">
            <p class="fontsize14 text-color-666 text-justify line-height2 p-3">
              <b-skeleton width="100%"></b-skeleton>
              <b-skeleton width="100%"></b-skeleton>
              <b-skeleton width="100%"></b-skeleton>
              <b-skeleton width="100%"></b-skeleton>
              <b-skeleton width="85%"></b-skeleton>
            </p>
          </div>
        </div>
      </section>
      <ScrollToTop></ScrollToTop>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import PagesPath from "@/parts/Front/components/PagesPath";
import ScrollToTop from "@/parts/Front/components/ScrollToTop";
import ProductServices from "@/parts/Front/components/productDetail/ProductServices";
import ProductComments from "@/parts/Front/components/productDetail/ProductComments";
import ProductQuestion from "@/parts/Front/components/productDetail/ProductQuestion";
import ProductSpecifications from "@/parts/Front/components/productDetail/ProductSpecifications";
import ProductReview from "@/parts/Front/components/productDetail/ProductReview";
import SimilarProducts from "@/parts/Front/components/productDetail/SimilarProducts";
import ProductContent from "@/parts/Front/components/productDetail/ProductContent";
import product from "@/parts/Front/components/productDetail/classes/product";
import ProductCongratulations from "@/parts/Front/components/productDetail/ProductCongratulations";
import config from "@/config";
import { BSkeleton } from "bootstrap-vue";

export default {
  name: "ProductDetails",
  components: {
    ProductCongratulations,
    PagesPath,
    ScrollToTop,
    ProductServices,
    ProductComments,
    ProductQuestion,
    ProductSpecifications,
    ProductReview,
    SimilarProducts,
    ProductContent,
    BSkeleton,
  },
  data() {
    return {
      url: "front/products/",
      title: "",
      disabled: false,
      relatedProducts: Array(5).fill(null),
      product: null,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.getProduct(this.$route.params.id);
    }
    this.onResize();
    window.removeEventListener("resize", this.onResize);
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      const bodyWidth = document.body.offsetWidth;
      const colorChooseImg = document.querySelectorAll(".color-choose-img")[0];
      // const productDetContent=document.querySelectorAll('.product-details-content')[0];
      if (colorChooseImg) {
        if (bodyWidth < 767) {
          colorChooseImg.style.width = bodyWidth - 100 + "px";
        }
      }
    },
    // changeTab(name) {
    //   // this.$store.commit('front/setProductActiveTab', name)
    //   this.activeTab = name
    // },
    async getProduct(id) {
      this.disabled = true;
      try {
        if (this.getInitialProductInfo) {
          this.product = this.getInitialProductInfo;
        }
        const response = await this.$store.dispatch(
          "front/getProductFromServer",
          id
        );
        this.product = new product(response.data.data.product, this);
        this.relatedProducts = response.data.data.relatedProducts1;

        // if (this.product.description != null) {
        //   this.activeTab = 'review'
        // } else if (this.product.description == null && this.product.specifications != '') {
        //   this.activeTab = 'specifications'
        // } else {
        //   this.activeTab = 'comments'
        // }
      } catch (error) {
        this.$root.errorNotify("محصول مورد نظر یافت نشد");
        this.$router.push("/");
      } finally {
        this.disabled = false;
      }
    },
    scrollToTab(selectedTab) {
      let element = document.getElementById(selectedTab);
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
  computed: {
    getInitialProductInfo() {
      return this.$store.getters["front/getSavedProductInfo"];
    },
  },
  metaInfo() {
    return {
      title: this.product
        ? `${this.product.title} ${config.title}`
        : config.title,
      meta: [
        {
          name: "description",
          content: this.product
            ? this.product.meta_description
              ? this.product.meta_description
              : this.product.short_description
            : false,
        },
        {
          property: "og:title",
          content: this.product ? this.product.title : false,
        },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
};
</script>

<style scoped></style>
<style>
div#isFree {
  background: #ff294f;
  color: white;
  padding: 4px 8px;
  border-radius: 15px;
  max-width: 100%;
  width: max-content;
  text-align: center;
  font-size: 14px;
  /* box-shadow: 0 0 5px #1213; */
}
@media screen and (max-width: 768px) {
  h2 {
    font-size: 1.6rem;
  }
  @media screen and (max-width: 400px) {
    .nav-tabs li a {
      font-size: 14px;
      padding: 0.25rem !important;
      margin: 0.12rem !important;
    }
  }
  div#isFree {
    background: #ff294f;
    color: white;
    padding: 4px 8px;
    border-radius: 15px 0 0 15px;
    text-align: center;
    font-size: 12px;
    border-right: 2px solid black;
  }
}
</style>
