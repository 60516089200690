import { render, staticRenderFns } from "./CustomerClub.vue?vue&type=template&id=53db52f2&scoped=true"
import script from "./CustomerClub.vue?vue&type=script&lang=js"
export * from "./CustomerClub.vue?vue&type=script&lang=js"
import style0 from "./CustomerClub.vue?vue&type=style&index=0&id=53db52f2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53db52f2",
  null
  
)

export default component.exports