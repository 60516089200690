<template>
  <div id="silentbox-gallery">
    <slot />
    <silentbox-overlay
      v-if="overlay.visible"
      :alt="alt"
      ref="overlay"
      :overlay-item="overlay.item"
      :visible="overlay.visible"
      :total-items="totalItems"
      @closeSilentboxOverlay="hideOverlay"
      @requestNextSilentBoxItem="showNextItem"
      @requestPreviousSilentBoxItem="showPreviousItem"
    />
  </div>
</template>

<script>
import overlay from "./Overlay.vue";
import itemMixin from "./../mixins/item";

export default {
  name: "Gallery",
  mixins: [itemMixin],
  props: {
    lazyLoading: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    previewCount: {
      type: Number,
      default: null,
    },
    alt: {
      type: String,
      default: "",
    },
    gallery: {
      type: Array,
      default: () => {
        return [];
      },
    },
    image: {
      type: Object,
      default: () => {
        return {
          src: "",
          alt: "",
          thumbnailWidth: "auto",
          thumbnailHeight: "auto",
          thumbnail: "",
          autoplay: false,
          controls: true,
          description: "",
        };
      },
    },
  },
  components: {
    "silentbox-overlay": overlay,
  },
  data() {
    return {
      overlay: {
        item: {
          src: "",
          alt: "",
          thumbnailWidth: "auto",
          thumbnailHeight: "auto",
          thumbnail: "",
          autoplay: false,
          controls: true,
          description: "",
        },
        visible: false,
        currentItem: 0,
      },
    };
  },
  computed: {
    totalItems() {
      return this.gallery.length || 1;
    },
    previewGallery() {
      if (Number.isInteger(this.previewCount)) {
        return this.gallery.slice(0, this.previewCount).map((item) => {
          return {
            ...this.overlay.item,
            ...item,
            thumbnail: this.setThumbnail(item),
            autoplay: this.setAutoplay(item),
          };
        });
      }
      return this.galleryItems;
    },
    galleryItems() {
      if (this.gallery.length > 0) {
        return this.gallery.map((item) => {
          return {
            ...this.overlay.item,
            ...item,
            thumbnail: this.setThumbnail(item),
            autoplay: this.setAutoplay(item),
          };
        });
      }
      return [
        {
          ...this.overlay.item,
          ...this.image,
          thumbnail: this.setThumbnail(this.image),
        },
      ];
    },
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.checkBackButton);
  },
  methods: {
    checkBackButton() {
      this.hideOverlay();
    },
    openOverlay(image, index = 0) {
      window.addEventListener("popstate", this.checkBackButton);
      this.$router.push("#gallery");
      this.overlay.visible = true;
      this.overlay.item = image;
      this.overlay.currentItem = index;
      this.$emit("silentbox-overlay-opened", { item: image });
    },
    hideOverlay() {
      window.removeEventListener("popstate", this.checkBackButton);
      if (this.$route.fullPath.includes("#gallery")) {
        this.$router.back();
      }
      this.$refs.overlay.removeScrollLock();
      this.overlay.visible = false;
      this.$emit("silentbox-overlay-hidden", { item: this.overlay.item });
    },
    showNextItem() {
      let newItemIndex = this.overlay.currentItem + 1;
      newItemIndex =
        newItemIndex <= this.galleryItems.length - 1 ? newItemIndex : 0;

      this.overlay.item = this.galleryItems[newItemIndex];
      this.overlay.currentItem = newItemIndex;
      this.$emit("silentbox-overlay-next-item-displayed", {
        item: this.overlay.item,
      });
    },
    showPreviousItem() {
      let newItemIndex = this.overlay.currentItem - 1;
      newItemIndex =
        newItemIndex > -1 ? newItemIndex : this.galleryItems.length - 1;

      this.overlay.item = this.galleryItems[newItemIndex];
      this.overlay.currentItem = newItemIndex;
      this.$emit("silentbox-overlay-previous-item-displayed", {
        item: this.overlay.item,
      });
    },
    setAutoplay(item) {
      return item.autoplay ? "autoplay" : "";
    },
    setThumbnail(item) {
      if (this.isEmbedVideo(item.src) && item.thumbnail === undefined) {
        return this.getThumbnail(item.src);
      }

      return item.thumbnail || item.src;
    },
  },
};
</script>

<style lang="scss"></style>
