import { render, staticRenderFns } from "./productsTab.vue?vue&type=template&id=6bd1f3b8&scoped=true"
import script from "./productsTab.vue?vue&type=script&lang=js"
export * from "./productsTab.vue?vue&type=script&lang=js"
import style0 from "./productsTab.vue?vue&type=style&index=0&id=6bd1f3b8&prod&scoped=true&lang=css"
import style1 from "./productsTab.vue?vue&type=style&index=1&id=6bd1f3b8&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bd1f3b8",
  null
  
)

export default component.exports