<template>
  <header
    class="main-header padding-t-20"
    v-if="!mediaQueries.large || $route.name !== 'cart'"
  >
    <section class="container">
      <!-- top section -->

      <div
        class="padding-b-10"
        id="fixedHeaderSection"
        style="border-bottom: 2px dashed var(--color-border)"
      >
        <div
          class="row d-flex align-items-center container justify-content-between justify-content-lg-start mx-auto"
        >
          <!-- hamburger menu -->
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center">
              <NavButton
                class="d-block d-xl-none"
                @click="allCategoriesClose()"
              />
              <AtlasLogo />
            </div>
          </div>
          <div class="d-none d-xl-flex pr-4" style="flex-grow: 1">
            <SearchButton class="bg-none" style="width: 500px" />
          </div>

          <div
            class="d-flex align-items-center"
            :class="
              isLogin
                ? 'justify-content-between gap-10'
                : 'justify-content-end  gap-20'
            "
          >
            <!-- login -->
            <div class="d-block">
              <!-- اگر لاگین نبود ایکن ورود نمایش داده شود -->
              <button
                v-if="!isLogin"
                id="loginButton"
                type="button"
                class="d-flex flex-column align-items-center bg-none"
                data-toggle="modal"
                data-target="#loginModal"
                style="gap: 8px"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="11.5789"
                    cy="7.27803"
                    r="4.77803"
                    stroke="#F83D84"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.00002 18.7014C3.99873 18.3655 4.07385 18.0337 4.2197 17.7311C4.67736 16.8158 5.96798 16.3307 7.03892 16.111C7.81128 15.9462 8.59431 15.836 9.38217 15.7815C10.8408 15.6533 12.3079 15.6533 13.7666 15.7815C14.5544 15.8367 15.3374 15.9468 16.1099 16.111C17.1808 16.3307 18.4714 16.77 18.9291 17.7311C19.2224 18.3479 19.2224 19.064 18.9291 19.6808C18.4714 20.6419 17.1808 21.0812 16.1099 21.2918C15.3384 21.4634 14.5551 21.5766 13.7666 21.6304C12.5794 21.7311 11.3866 21.7494 10.1968 21.6854C9.92221 21.6854 9.65677 21.6854 9.38217 21.6304C8.59663 21.5773 7.81632 21.4641 7.04807 21.2918C5.96798 21.0812 4.68652 20.6419 4.2197 19.6808C4.0746 19.3747 3.99955 19.0401 4.00002 18.7014Z"
                    stroke="#F83D84"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span class="font-s-12 text-color-theme">ورود / عضویت</span>
              </button>
              <template v-if="isLogin">
                <LoginUser :atlasTheme="false" />
              </template>
            </div>
            <!-- cart -->
            <CartButton />
            <!-- notification -->
            <div
              v-if="
                notifications &&
                notifications.items &&
                notifications.items.length
              "
              class="d-flex align-items-center flex-column"
            >
              <div
                class="d-inline-block"
                :class="
                  notifications &&
                  notifications.items &&
                  notifications.items.length
                    ? 'ml-4'
                    : 'ml-1'
                "
              >
                <HeaderNotification
                  v-if="notifications"
                  v-show="
                    notifications &&
                    notifications.items &&
                    notifications.items.length
                  "
                  :value="notifications"
                />
              </div>
              <span class="font-s-12 text-color-theme pt-1">پیام ها</span>
            </div>
          </div>
        </div>
      </div>
      <!-- bottom section -->

      <div
        class="d-xl-flex align-items-center justify-content-md-between padding-t-10"
      >
        <Nav class="d-none d-xl-block"></Nav>
        <div class="d-flex align-items-center justify-content-between gap-10">
          <div class="d-flex align-items-center">
            <p class="m-0 font-weight-bold fontsize-mini">پشتیبانی</p>
          </div>
          <div
            class="d-flex align-items-center gap-10 bg-color-eai border-r-25"
          >
            <a
              class="fontsize-mini text-light bg-color-theme padding-t-2 padding-b-2 padding-r-10 padding-l-10"
              style="border-radius: 25px 25px 25px 0"
              :href="`tel:${
                content?.settings?.site?.mobile_support.split('-')[0]
              }`"
              >{{ content?.settings?.site?.mobile_support.split("-")[0] }}</a
            >
            <!-- <a
              class="fontsize-mini text-dark padding-l-10"
              style="border-radius: 25px 25px 25px 0"
              :href="`tel:${
                content?.settings?.site?.mobile_support.split('-')[1]
              }`"
              >{{ content?.settings?.site?.mobile_support.split("-")[1] }}</a
            > -->
            <!-- <a
              class="fontsize-mini text-dark padding-l-10"
              :href="`mailto:${content?.settings?.site?.email}`"
              >{{ content?.settings?.site?.email }}</a
            > -->
          </div>
        </div>
      </div>

      <!-- search box mobile -->
      <!-- <SearchButton class="bg-none d-flex d-xl-none mt-4 mt-xl-0" /> -->
      <div
        @click="showCloseSearchBox()"
        class="gap-15 align-items-center justify-content-between pl-3 my-3 d-md-none d-flex border border-r-25"
      >
        <p
          class="w-100 m-0 text-grey-8b fontsize-mini py-2 pl-5 pr-3 border-r-25 bg-none"
        >
          جستجو در محصولات بندیتو...
        </p>

        <button type="button" class="bg-none">
          <span class="d-flex align-items-center gap-10">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.6667 12.1667H10C9.72667 12.1667 9.5 11.94 9.5 11.6667C9.5 11.3933 9.72667 11.1667 10 11.1667H14.6667C14.94 11.1667 15.1667 11.3933 15.1667 11.6667C15.1667 11.94 14.94 12.1667 14.6667 12.1667Z"
                fill="#ADADAD"
              />
              <path
                d="M3.33398 12.1667H1.33398C1.06065 12.1667 0.833984 11.94 0.833984 11.6667C0.833984 11.3933 1.06065 11.1667 1.33398 11.1667H3.33398C3.60732 11.1667 3.83398 11.3933 3.83398 11.6667C3.83398 11.94 3.60732 12.1667 3.33398 12.1667Z"
                fill="#ADADAD"
              />
              <path
                d="M14.666 4.83333H12.666C12.3927 4.83333 12.166 4.60667 12.166 4.33333C12.166 4.06 12.3927 3.83333 12.666 3.83333H14.666C14.9393 3.83333 15.166 4.06 15.166 4.33333C15.166 4.60667 14.9393 4.83333 14.666 4.83333Z"
                fill="#ADADAD"
              />
              <path
                d="M6.00065 4.83333H1.33398C1.06065 4.83333 0.833984 4.60667 0.833984 4.33333C0.833984 4.06 1.06065 3.83333 1.33398 3.83333H6.00065C6.27398 3.83333 6.50065 4.06 6.50065 4.33333C6.50065 4.60667 6.27398 4.83333 6.00065 4.83333Z"
                fill="#ADADAD"
              />
              <path
                d="M8.66732 14.1667H4.66732C3.52065 14.1667 2.83398 13.48 2.83398 12.3333V11C2.83398 9.85333 3.52065 9.16667 4.66732 9.16667H8.66732C9.81398 9.16667 10.5007 9.85333 10.5007 11V12.3333C10.5007 13.48 9.81398 14.1667 8.66732 14.1667ZM4.66732 10.1667C4.07398 10.1667 3.83398 10.4067 3.83398 11V12.3333C3.83398 12.9267 4.07398 13.1667 4.66732 13.1667H8.66732C9.26065 13.1667 9.50065 12.9267 9.50065 12.3333V11C9.50065 10.4067 9.26065 10.1667 8.66732 10.1667H4.66732Z"
                fill="#ADADAD"
              />
              <path
                d="M11.3333 6.83333H7.33333C6.18667 6.83333 5.5 6.14667 5.5 5V3.66667C5.5 2.52 6.18667 1.83333 7.33333 1.83333H11.3333C12.48 1.83333 13.1667 2.52 13.1667 3.66667V5C13.1667 6.14667 12.48 6.83333 11.3333 6.83333ZM7.33333 2.83333C6.74 2.83333 6.5 3.07333 6.5 3.66667V5C6.5 5.59333 6.74 5.83333 7.33333 5.83333H11.3333C11.9267 5.83333 12.1667 5.59333 12.1667 5V3.66667C12.1667 3.07333 11.9267 2.83333 11.3333 2.83333H7.33333Z"
                fill="#ADADAD"
              />
            </svg>

            <svg
              @click.prevent="gotoSearchPage"
              width="18"
              height="17"
              viewBox="0 0 18 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.3049 4.35233H10.1467C9.86258 4.35233 9.62695 4.1167 9.62695 3.83256C9.62695 3.54842 9.86258 3.3128 10.1467 3.3128H14.3049C14.589 3.3128 14.8246 3.54842 14.8246 3.83256C14.8246 4.1167 14.589 4.35233 14.3049 4.35233Z"
                fill="#565656"
              />
              <path
                d="M12.2258 6.43142H10.1467C9.86258 6.43142 9.62695 6.1958 9.62695 5.91166C9.62695 5.62752 9.86258 5.39189 10.1467 5.39189H12.2258C12.5099 5.39189 12.7456 5.62752 12.7456 5.91166C12.7456 6.1958 12.5099 6.43142 12.2258 6.43142Z"
                fill="#565656"
              />
              <path
                d="M8.41599 15.4407C4.50041 15.4407 1.3125 12.2528 1.3125 8.33719C1.3125 4.42161 4.50041 1.2337 8.41599 1.2337C8.70013 1.2337 8.93576 1.46933 8.93576 1.75347C8.93576 2.03761 8.70013 2.27324 8.41599 2.27324C5.06869 2.27324 2.35203 4.99682 2.35203 8.33719C2.35203 11.6776 5.06869 14.4011 8.41599 14.4011C11.7633 14.4011 14.4799 11.6776 14.4799 8.33719C14.4799 8.05305 14.7156 7.81742 14.9997 7.81742C15.2838 7.81742 15.5195 8.05305 15.5195 8.33719C15.5195 12.2528 12.3316 15.4407 8.41599 15.4407Z"
                fill="#565656"
              />
              <path
                d="M15.6931 16.1337C15.5615 16.1337 15.4298 16.0852 15.3258 15.9812L13.9398 14.5952C13.7388 14.3942 13.7388 14.0616 13.9398 13.8606C14.1408 13.6596 14.4734 13.6596 14.6744 13.8606L16.0604 15.2466C16.2614 15.4476 16.2614 15.7802 16.0604 15.9812C15.9565 16.0852 15.8248 16.1337 15.6931 16.1337Z"
                fill="#565656"
              />
            </svg>
          </span>
        </button>
      </div>
    </section>

    <MainSearch></MainSearch>
    <RespLoginModal></RespLoginModal>
    <CartLists></CartLists>
    <RespMenu ref="respMenu"></RespMenu>
    <div
      id="header_bg_img"
      :class="{ 'ClubDetail-header_bg_img': $route.name == 'ClubDetail' }"
    >
      <img class="w-100" src="../../../../assets/images/headerbg.png" alt="" />
    </div>
  </header>
</template>

<script>
import AtlasLogo from "@/parts/Front/components/AtlasLogo";
import Nav from "@/parts/Front/components/Nav";
// import ProductCategories from "@/parts/Front/components/ProductCategories";
import RespMenu from "@/parts/Front/components/RespMenu";
import MainSearch from "@/parts/Front/components/MainSearch";
import LoginUser from "@/parts/Front/components/LoginUser";
import RespLoginModal from "@/parts/Front/components/RespLoginModal";
import CartLists from "@/parts/Front/components/CartLists";
import HeaderNotification from "@/parts/Front/components/notification/HeaderNotification";
import CartButton from "@/parts/Front/components/cart/CartButton";
import SearchButton from "../header/SearchButton";
import NavButton from "@/parts/Front/components/shared/NavButton";
import { openSearch } from "../../../../libraries/functions";
import cart from "@/parts/Front/components/cart/cart";

export default {
  name: "Header",
  components: {
    NavButton,
    SearchButton,
    AtlasLogo,
    HeaderNotification,
    Nav,
    LoginUser,
    // ProductCategories,
    RespMenu,
    MainSearch,
    RespLoginModal,
    CartLists,
    CartButton,
  },
  inject: ["mediaQueries"],
  methods: {
    allCategoriesClose() {
      this.$refs.respMenu.allCategoriesToggle();
    },
    signout() {
      window
        .swal({
          title: "آیا میخواهید خارج شوید؟",
          icon: "warning",
          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله خارج شو"],
        })
        .then((willDelete) => {
          if (willDelete) {
            this.disabledlLogout = true;
            this.$axios.post("/customer/logout").then((res) => {
              this.disabledlLogout = false;
              this.showOptions = false;
              // پاک کردن اطلاعات مربوط به کاربر از کوکی و ویو ایکس
              delete this.$axios.defaults.headers.common["Authorization"];
              this.$root.deleteCookie("Authorization");
              this.$store.commit("front/setLoginStatus", false);
              if (this.cart) {
                this.cart.emptyCookie();
              }
            });
          }
        });
    },
    isScroll() {
      if (scrollY > 150) {
        document
          .getElementById("fixedHeaderSection")
          .classList.add("fixedHeader");
      } else {
        document
          .getElementById("fixedHeaderSection")
          .classList.remove("fixedHeader");
      }
    },
    showCloseSearchBox() {
      openSearch();
    },
  },
  mounted() {
    if (this.isLogin) {
      this.$store.dispatch("front/getNotficationsFromServer");
    }
    window.addEventListener("scroll", this.isScroll);
  },
  computed: {
    // چک کردن لاگین بودن کاربر با استفاده از ویو ایکس در سند یوزر
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
    // دریافت اطلاعات کاربر
    FullName() {
      return this.$store.getters["front/getFullName"];
    },
    notifications() {
      return this.$store.getters["front/getNotifications"];
    },
    content() {
      return this.$store.getters["front/getHomeData"];
    },
    cart() {
      if (this.$store.getters["front/getHomeData"]) {
        return this.$store.getters["front/getHomeData"].user.cart;
      } else {
        return null;
      }
    },
  },
  data() {
    return {
      disabledlLogout: false,
    };
  },
};
</script>

<style scoped>
#header_bg_img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  height: 100%;
}
.ClubDetail-header_bg_img img {
  height: 400px;
}
.main-header {
  /* background-color: rgb(250, 250, 250); */
  /* padding-bottom: 150px; */
  padding-bottom: 10px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
div#logoBack {
  position: absolute;
  background: white;
  width: 170px;
  height: 80px;
  z-index: -1;
  border-radius: 0 0 20px 20px;
  top: -1rem;
  right: -15px;
}
.fixedHeader {
  position: fixed;
  top: -50px;
  background: #ffffff;
  right: 0;
  left: 0;
  z-index: 1040;
  padding-top: 10px;
  padding-bottom: 10px;
  animation: fixedHeaderAnimation 0.5s linear forwards;
  border-bottom: unset !important;
}
@keyframes fixedHeaderAnimation {
  0% {
    top: -50px;
  }
  100% {
    top: 0;
  }
}

@media screen and (min-width: 770px) {
  .fixedHeader > div {
    width: 90%;
    margin-inline: auto;
  }
}
@media screen and (max-width: 769px) {
  #header_bg_img img {
    height: 100%;
  }
  #header_bg_img.ClubDetail-header_bg_img img {
    height: 410px;
  }
}
@media screen and (max-width: 1250px) {
  .ClubDetail-header_bg_img img {
    height: 460px;
  }
}
@media screen and (max-width: 1000px) {
  .ClubDetail-header_bg_img img {
    height: 410px;
  }
}
</style>
