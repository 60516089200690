<template>
  <div class="position-relative" id="serachBoxContainer">
    <div
      class="gap-15 align-items-center justify-content-between pl-3 d-md-flex d-none"
      :class="isFocused ? 'focusedInput' : 'unfocusedInput'"
      @click="isFocused = true"
    >
      <input
        :value="search"
        @input="(e) => (search = e.target.value)"
        type="text"
        @keypress.enter="gotoSearchPage"
        class="w-100 m-0 text-grey-8b fontsize-mini py-2 pl-5 pr-3 border-r-25 bg-none"
        placeholder="جستجو در محصولات بندیتو..."
      />
      <button type="button" class="bg-none">
        <span class="d-flex align-items-center gap-10">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.6667 12.1667H10C9.72667 12.1667 9.5 11.94 9.5 11.6667C9.5 11.3933 9.72667 11.1667 10 11.1667H14.6667C14.94 11.1667 15.1667 11.3933 15.1667 11.6667C15.1667 11.94 14.94 12.1667 14.6667 12.1667Z"
              fill="#ADADAD"
            />
            <path
              d="M3.33398 12.1667H1.33398C1.06065 12.1667 0.833984 11.94 0.833984 11.6667C0.833984 11.3933 1.06065 11.1667 1.33398 11.1667H3.33398C3.60732 11.1667 3.83398 11.3933 3.83398 11.6667C3.83398 11.94 3.60732 12.1667 3.33398 12.1667Z"
              fill="#ADADAD"
            />
            <path
              d="M14.666 4.83333H12.666C12.3927 4.83333 12.166 4.60667 12.166 4.33333C12.166 4.06 12.3927 3.83333 12.666 3.83333H14.666C14.9393 3.83333 15.166 4.06 15.166 4.33333C15.166 4.60667 14.9393 4.83333 14.666 4.83333Z"
              fill="#ADADAD"
            />
            <path
              d="M6.00065 4.83333H1.33398C1.06065 4.83333 0.833984 4.60667 0.833984 4.33333C0.833984 4.06 1.06065 3.83333 1.33398 3.83333H6.00065C6.27398 3.83333 6.50065 4.06 6.50065 4.33333C6.50065 4.60667 6.27398 4.83333 6.00065 4.83333Z"
              fill="#ADADAD"
            />
            <path
              d="M8.66732 14.1667H4.66732C3.52065 14.1667 2.83398 13.48 2.83398 12.3333V11C2.83398 9.85333 3.52065 9.16667 4.66732 9.16667H8.66732C9.81398 9.16667 10.5007 9.85333 10.5007 11V12.3333C10.5007 13.48 9.81398 14.1667 8.66732 14.1667ZM4.66732 10.1667C4.07398 10.1667 3.83398 10.4067 3.83398 11V12.3333C3.83398 12.9267 4.07398 13.1667 4.66732 13.1667H8.66732C9.26065 13.1667 9.50065 12.9267 9.50065 12.3333V11C9.50065 10.4067 9.26065 10.1667 8.66732 10.1667H4.66732Z"
              fill="#ADADAD"
            />
            <path
              d="M11.3333 6.83333H7.33333C6.18667 6.83333 5.5 6.14667 5.5 5V3.66667C5.5 2.52 6.18667 1.83333 7.33333 1.83333H11.3333C12.48 1.83333 13.1667 2.52 13.1667 3.66667V5C13.1667 6.14667 12.48 6.83333 11.3333 6.83333ZM7.33333 2.83333C6.74 2.83333 6.5 3.07333 6.5 3.66667V5C6.5 5.59333 6.74 5.83333 7.33333 5.83333H11.3333C11.9267 5.83333 12.1667 5.59333 12.1667 5V3.66667C12.1667 3.07333 11.9267 2.83333 11.3333 2.83333H7.33333Z"
              fill="#ADADAD"
            />
          </svg>

          <svg
            @click.prevent="gotoSearchPage"
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.3049 4.35233H10.1467C9.86258 4.35233 9.62695 4.1167 9.62695 3.83256C9.62695 3.54842 9.86258 3.3128 10.1467 3.3128H14.3049C14.589 3.3128 14.8246 3.54842 14.8246 3.83256C14.8246 4.1167 14.589 4.35233 14.3049 4.35233Z"
              fill="#565656"
            />
            <path
              d="M12.2258 6.43142H10.1467C9.86258 6.43142 9.62695 6.1958 9.62695 5.91166C9.62695 5.62752 9.86258 5.39189 10.1467 5.39189H12.2258C12.5099 5.39189 12.7456 5.62752 12.7456 5.91166C12.7456 6.1958 12.5099 6.43142 12.2258 6.43142Z"
              fill="#565656"
            />
            <path
              d="M8.41599 15.4407C4.50041 15.4407 1.3125 12.2528 1.3125 8.33719C1.3125 4.42161 4.50041 1.2337 8.41599 1.2337C8.70013 1.2337 8.93576 1.46933 8.93576 1.75347C8.93576 2.03761 8.70013 2.27324 8.41599 2.27324C5.06869 2.27324 2.35203 4.99682 2.35203 8.33719C2.35203 11.6776 5.06869 14.4011 8.41599 14.4011C11.7633 14.4011 14.4799 11.6776 14.4799 8.33719C14.4799 8.05305 14.7156 7.81742 14.9997 7.81742C15.2838 7.81742 15.5195 8.05305 15.5195 8.33719C15.5195 12.2528 12.3316 15.4407 8.41599 15.4407Z"
              fill="#565656"
            />
            <path
              d="M15.6931 16.1337C15.5615 16.1337 15.4298 16.0852 15.3258 15.9812L13.9398 14.5952C13.7388 14.3942 13.7388 14.0616 13.9398 13.8606C14.1408 13.6596 14.4734 13.6596 14.6744 13.8606L16.0604 15.2466C16.2614 15.4476 16.2614 15.7802 16.0604 15.9812C15.9565 16.0852 15.8248 16.1337 15.6931 16.1337Z"
              fill="#565656"
            />
          </svg>
        </span>
      </button>
    </div>
    <div v-if="isFocused" class="searchSuggestion">
      <div id="spinnerSearchNew" class="mt-5" v-show="status">
        <div class="hollow-dots-spinner">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
      <div v-if="showResult">
        <div v-if="products">
          <div id="tableBoxNew" v-if="products != null && products.length != 0">
            <table class="table">
              <tbody>
                <tr
                  class="searchItem"
                  v-for="result in products"
                  :key="result.id"
                  @click="showProduct(result)"
                >
                  <th scope="row">
                    <img
                      class="resultImage"
                      v-if="result.major_image != null"
                      :src="result.major_image.url"
                      :alt="result.title"
                    />

                    <div v-else class="notImage"></div>
                  </th>
                  <td class="shortDescSearch">
                    <span>{{ result.title }}</span>
                    <span class="searchPrice"
                      >{{ result.price | price }} تومان</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="products.length == 8" id="showMoreDivNew">
              <button id="showMoreBtnNew" @click.prevent="gotoSearchPage">
                مشاهده بیشتر
                <i class="fa fa-angle-down"></i>
              </button>
            </div>
          </div>
          <div
            id="notFoundProductNew"
            v-if="
              (products == null || products.length == 0) && searching == false
            "
          >
            نتیجه ایی پیدا نشد
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchButton",
  data() {
    return {
      isFocused: false,
      searchList: [],
      search: "",
      showResult: false,
      searching: false,
    };
  },
  mounted() {
    this.clickOutside();
  },
  methods: {
    opened() {
      this.search = "";
    },
    showProduct(product) {
      this.showResult = false;
      setTimeout(() => {
        this.$router.push({
          name: "product.detail",
          params: { id: product.id, slug: product.slug },
        });
      }, 1000);
    },
    // این متد برای زمانی که بروی اینپوت کلیک میشود فراخوانی میشود
    focusInput() {
      if (this.products != null) {
        this.showResult = true;
      }
    },
    // جستجو در میان لیست محصولات بعد از پایان نوشتن مشتری
    searchRequest() {
      if (this.search != "" && this.search.length > 1) {
        this.showResult = true;
        this.searching = true;
        this.$store.dispatch("front/searchInProducts", this.search);
      }
    },
    // تابع با فشردن اینتر به صفحه محصولات هدایت میکند
    gotoSearchPage() {
      let search = this.search;
      if (this.$route.name == "products") {
        this.search = "";
        this.$router.push({ name: "products", query: { title: search } });
      } else {
        return this.$router.push({
          name: "products",
          query: { title: search },
        });
      }
    },
    clickOutside() {
      const specifiedElement = document.getElementById("serachBoxContainer");
      document.addEventListener("click", (event) => {
        const isClickInside = specifiedElement.contains(event.target);
        if (!isClickInside) {
          this.isFocused = false;
          this.search = "";
        }
      });
    },
  },
  watch: {
    status(val) {
      if (val == false) {
        this.searching = false;
      }
    },
    search(newVal) {
      // تماشا کردن تغییرات اینپوت سرچ و اگر بعد از اخرین حرف وارده حرفی وارد نکند سرچ انجام میشود
      this.searchList.push(newVal);
      let last = this.searchList.length;
      setTimeout(() => {
        if (this.searchList[last] == undefined) {
          this.searchList = [];
          this.searchRequest();
        }
      }, 1500);
    },
  },
  computed: {
    // استاتوس تغییر وضعیت برای نمایش لودینگ
    status() {
      return this.$store.getters["front/getSearchStatus"];
    },
    // دریافت محصولات پیدا شده از ویو ایکس
    products() {
      return this.$store.getters["front/getSearchResult"];
    },
  },
};
</script>

<style scoped>
/* اسپینر */
.hollow-dots-spinner,
.hollow-dots-spinner * {
  box-sizing: border-box;
}

.hollow-dots-spinner {
  height: 15px;
  width: calc(30px * 3);
}

.hollow-dots-spinner .dot {
  width: 15px;
  height: 15px;
  margin: 0 calc(15px / 2);
  border: calc(15px / 5) solid var(--color-theme3);
  border-radius: 50%;
  float: left;
  transform: scale(0);
  animation: hollow-dots-spinner-animation 1000ms ease infinite 0ms;
}

.hollow-dots-spinner .dot:nth-child(1) {
  animation-delay: calc(300ms * 1);
}

.hollow-dots-spinner .dot:nth-child(2) {
  animation-delay: calc(300ms * 2);
}

.hollow-dots-spinner .dot:nth-child(3) {
  animation-delay: calc(300ms * 3);
}

@keyframes hollow-dots-spinner-animation {
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.searchSuggestion {
  height: 400px;
  width: 100%;
  position: absolute;
  z-index: 1030;
  background-color: #ffffff;
  box-shadow: 0 50px 50px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 10px 10px;
  transition: all 1s linear;
  overflow-y: scroll;
}
.searchSuggestion::after {
  content: "";
  display: block;
  position: absolute;
  top: 2%;
  right: 50%;
  transform: translateX(50%);
  width: 95%;
  height: 1px;
  margin-inline: auto;
  background-color: var(--color-main);
}
.focusedInput {
  padding-top: 10px;
  border-radius: 10px 10px 0 0;
  background-color: #ffffff;
}
.unfocusedInput {
  border: 1px solid #cecece;
  border-radius: 25px;
  background-color: transparent;
}

#spinnerSearchNew {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
#tableBoxNew {
  /* max-height: 300px; */
  overflow: auto;
}
#tableBoxNew .table {
  margin-bottom: 0 !important;
  margin-top: 10px;
}
.table th,
.table td {
  border-top: 0 !important;
}
.notImage {
  width: 80px;
  height: 80px;
  background-color: silver;
}
.resultImage {
  cursor: pointer;
  max-width: 60px;
  max-height: 75px;
  object-fit: fill;
}

#showMoreDivNew {
  display: flex;
  justify-content: center;
}
#showMoreBtnNew {
  background-color: transparent;
}
#showMoreBtnNew i {
  position: relative;
  top: 4px;
}
#notFoundProductNew {
  position: relative;
  top: 15px;
  text-align: center;
}
.searchItem {
  cursor: pointer;
}
.shortDescSearch {
  flex-direction: column;
  vertical-align: bottom;
  height: 100px;
  display: flex;
  justify-content: center;
}
.searchPrice {
  color: #ff2d2d;
}

table tr > td:nth-child(1),
table tr > th:nth-child(1) {
  width: 86px;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: var(--color-main);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--color-main);
}
</style>
