<template>
  <section class="articles">
    <div class="container">
      <div
        class="d-flex align-items-center justify-content-between gap-15 mb-3"
      >
        <div class="d-flex align-items-center gap-5 text-color-grey">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.6667 20.8541H7.33333C3.9875 20.8541 2.0625 18.9291 2.0625 15.5833V6.41658C2.0625 3.07075 3.9875 1.14575 7.33333 1.14575H14.6667C18.0125 1.14575 19.9375 3.07075 19.9375 6.41658V15.5833C19.9375 18.9291 18.0125 20.8541 14.6667 20.8541ZM7.33333 2.52075C4.71167 2.52075 3.4375 3.79492 3.4375 6.41658V15.5833C3.4375 18.2049 4.71167 19.4791 7.33333 19.4791H14.6667C17.2883 19.4791 18.5625 18.2049 18.5625 15.5833V6.41658C18.5625 3.79492 17.2883 2.52075 14.6667 2.52075H7.33333Z"
              fill="#434343"
            />
            <path
              d="M16.9584 8.47917H15.1251C13.7317 8.47917 12.6042 7.35167 12.6042 5.95833V4.125C12.6042 3.74917 12.9159 3.4375 13.2917 3.4375C13.6676 3.4375 13.9792 3.74917 13.9792 4.125V5.95833C13.9792 6.59083 14.4926 7.10417 15.1251 7.10417H16.9584C17.3342 7.10417 17.6459 7.41583 17.6459 7.79167C17.6459 8.1675 17.3342 8.47917 16.9584 8.47917Z"
              fill="#434343"
            />
            <path
              d="M10.9999 12.6042H7.33325C6.95742 12.6042 6.64575 12.2926 6.64575 11.9167C6.64575 11.5409 6.95742 11.2292 7.33325 11.2292H10.9999C11.3758 11.2292 11.6874 11.5409 11.6874 11.9167C11.6874 12.2926 11.3758 12.6042 10.9999 12.6042Z"
              fill="#434343"
            />
            <path
              d="M14.6666 16.2708H7.33325C6.95742 16.2708 6.64575 15.9591 6.64575 15.5833C6.64575 15.2074 6.95742 14.8958 7.33325 14.8958H14.6666C15.0424 14.8958 15.3541 15.2074 15.3541 15.5833C15.3541 15.9591 15.0424 16.2708 14.6666 16.2708Z"
              fill="#434343"
            />
          </svg>
          <h3
            class="m-0 d-inline-block fontsize-heavy"
            style="font-weight: 900"
          >
            مطالب
          </h3>
          <p class="fontsize-small m-0">وبلاگ</p>
        </div>
        <div class="custom-line d-none d-xl-block"></div>
        <router-link
          to="/weblog-list"
          class="campaigns-more-btn py-2 px-3 text-light text-color-444 fontsize-small w-s-nowrap"
        >
          مطالب بیشتر
        </router-link>
      </div>
      <b-row v-if="status">
        <b-col>
          <b-skeleton-img></b-skeleton-img> <br />
          <b-skeleton></b-skeleton>
        </b-col>
        <b-col>
          <b-skeleton-img></b-skeleton-img><br />
          <b-skeleton></b-skeleton>
        </b-col>
        <b-col>
          <b-skeleton-img></b-skeleton-img><br />
          <b-skeleton></b-skeleton>
        </b-col>
        <b-col>
          <b-skeleton-img></b-skeleton-img><br />
          <b-skeleton></b-skeleton>
        </b-col>
      </b-row>
      <div id="blogArticleContent" v-else class="">
        <!-- دریافت اطلاعات از آبجکت هوم و وارد شدن به آبجکت پست -->
        <template v-if="blogs != null">
          <div class="swiper-weblog">
            <div class="swiper-container h-100">
              <div class="swiper-wrapper">
                <article
                  v-for="(blog, index) in blogs.post"
                  :key="'Blog' + index"
                  class="weblog-card border-r-25 swiper-slide"
                  :title="blog.title"
                >
                  <img
                    loading="lazy"
                    v-if="blog.image"
                    :src="blog.image.url"
                    :alt="blog.title"
                  />
                  <div class="card-info padding-r-10 padding-l-10">
                    <h2
                      class="fontsize-medium text-light m-0 text-overflow-control"
                    >
                      {{ blog.title }}
                    </h2>
                    <p class="fontsize-mini m-0 padding-t-10">
                      {{ blog.summary.substr(0, blogSummaryLength) }}
                      {{ blog.summary.length > blogSummaryLength ? "..." : "" }}
                    </p>
                    <div
                      class="d-flex flex-wrap align-items-center justify-content-between padding-r-5 padding-l-5 padding-t-5"
                    >
                      <div>
                        <span>
                          <svg
                            width="15"
                            height="16"
                            viewBox="0 0 15 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.55744 4.33159C5.31677 4.33159 5.11719 4.13201 5.11719 3.89134V2.13032C5.11719 1.88965 5.31677 1.69006 5.55744 1.69006C5.79811 1.69006 5.9977 1.88965 5.9977 2.13032V3.89134C5.9977 4.13201 5.79811 4.33159 5.55744 4.33159Z"
                              fill="white"
                            />
                            <path
                              d="M10.2528 4.33159C10.0121 4.33159 9.8125 4.13201 9.8125 3.89134V2.13032C9.8125 1.88965 10.0121 1.69006 10.2528 1.69006C10.4934 1.69006 10.693 1.88965 10.693 2.13032V3.89134C10.693 4.13201 10.4934 4.33159 10.2528 4.33159Z"
                              fill="white"
                            />
                            <path
                              d="M5.85068 9.46796C5.77437 9.46796 5.69805 9.45036 5.62761 9.42101C5.5513 9.39166 5.49261 9.35055 5.43391 9.29772C5.32825 9.18619 5.26367 9.03358 5.26367 8.88095C5.26367 8.72833 5.32825 8.57572 5.43391 8.46418C5.49261 8.41135 5.55717 8.37025 5.62761 8.3409C5.76849 8.2822 5.93286 8.2822 6.07374 8.3409C6.14418 8.37025 6.20875 8.41135 6.26745 8.46418C6.29093 8.49354 6.32028 8.52288 6.33789 8.55223C6.36137 8.58745 6.37899 8.62267 6.39073 8.65789C6.40834 8.69311 6.42008 8.72833 6.42595 8.76355C6.43182 8.80464 6.43768 8.84573 6.43768 8.88095C6.43768 9.03358 6.37311 9.18619 6.26745 9.29772C6.20875 9.35055 6.14418 9.39166 6.07374 9.42101C6.0033 9.45036 5.92699 9.46796 5.85068 9.46796Z"
                              fill="white"
                            />
                            <path
                              d="M7.90537 9.46782C7.82905 9.46782 7.75274 9.45022 7.6823 9.42086C7.60599 9.39151 7.5473 9.35041 7.4886 9.29758C7.38294 9.18605 7.31836 9.03343 7.31836 8.88081C7.31836 8.84559 7.32422 8.80449 7.33009 8.7634C7.33596 8.72818 7.3477 8.69297 7.36531 8.65775C7.37705 8.62253 7.39468 8.58731 7.41816 8.55209C7.44164 8.52274 7.46512 8.49339 7.4886 8.46404C7.70579 8.24685 8.09907 8.24685 8.32213 8.46404C8.34562 8.49339 8.36909 8.52274 8.39257 8.55209C8.41605 8.58731 8.43368 8.62253 8.44542 8.65775C8.46303 8.69297 8.47477 8.72818 8.48064 8.7634C8.48651 8.80449 8.49237 8.84559 8.49237 8.88081C8.49237 9.03343 8.4278 9.18605 8.32213 9.29758C8.2106 9.40324 8.06386 9.46782 7.90537 9.46782Z"
                              fill="white"
                            />
                            <path
                              d="M5.85068 11.5225C5.77437 11.5225 5.69805 11.5049 5.62761 11.4756C5.55717 11.4462 5.49261 11.4051 5.43391 11.3523C5.32825 11.2408 5.26367 11.0881 5.26367 10.9355C5.26367 10.9003 5.26954 10.8592 5.27541 10.824C5.28128 10.7829 5.29301 10.7477 5.31062 10.7125C5.32236 10.6772 5.33999 10.642 5.36347 10.6068C5.38108 10.5774 5.41043 10.5481 5.43391 10.5188C5.49261 10.4659 5.55717 10.4248 5.62761 10.3955C5.76849 10.3368 5.93286 10.3368 6.07374 10.3955C6.14418 10.4248 6.20875 10.4659 6.26745 10.5188C6.29093 10.5481 6.32028 10.5774 6.33789 10.6068C6.36137 10.642 6.37899 10.6772 6.39073 10.7125C6.40834 10.7477 6.42008 10.7829 6.42595 10.824C6.43182 10.8592 6.43768 10.9003 6.43768 10.9355C6.43768 11.0881 6.37311 11.2408 6.26745 11.3523C6.20875 11.4051 6.14418 11.4462 6.07374 11.4756C6.0033 11.5049 5.92699 11.5225 5.85068 11.5225Z"
                              fill="white"
                            />
                            <path
                              d="M12.8959 6.73219H2.91682C2.67614 6.73219 2.47656 6.53261 2.47656 6.29194C2.47656 6.05127 2.67614 5.85168 2.91682 5.85168H12.8959C13.1366 5.85168 13.3362 6.05127 13.3362 6.29194C13.3362 6.53261 13.1366 6.73219 12.8959 6.73219Z"
                              fill="white"
                            />
                            <path
                              d="M10.1466 14.3277C9.92356 14.3277 9.71224 14.2455 9.55962 14.0929C9.37765 13.9109 9.29547 13.6468 9.33656 13.3709L9.44809 12.5784C9.47744 12.373 9.60072 12.1264 9.74747 11.9797L11.8255 9.90166C12.1072 9.6199 12.3831 9.47315 12.6825 9.4438C13.0523 9.40858 13.4104 9.5612 13.7508 9.90166C14.1089 10.2597 14.5903 10.9876 13.7508 11.827L11.6728 13.905C11.5261 14.0518 11.2795 14.1751 11.0741 14.2044L10.2816 14.316C10.2347 14.3218 10.1936 14.3277 10.1466 14.3277ZM12.7823 10.3184C12.7764 10.3184 12.7705 10.3184 12.7647 10.3184C12.6825 10.3243 12.571 10.4006 12.4477 10.5239L10.3697 12.6019C10.3521 12.6195 10.3227 12.6782 10.3227 12.7017L10.2171 13.4354L10.9508 13.3298C10.9743 13.3239 11.033 13.2946 11.0506 13.2769L13.1286 11.199C13.3869 10.9407 13.4221 10.8115 13.1286 10.518C13.0347 10.43 12.8997 10.3184 12.7823 10.3184Z"
                              fill="white"
                            />
                            <path
                              d="M13.1423 12.2554C13.1012 12.2554 13.0601 12.2496 13.0249 12.2378C12.2501 12.0206 11.6337 11.4043 11.4165 10.6294C11.3519 10.3946 11.4869 10.1539 11.7217 10.0835C11.9565 10.0189 12.1972 10.154 12.2677 10.3888C12.4027 10.8701 12.7842 11.2516 13.2656 11.3867C13.5004 11.4512 13.6354 11.6978 13.5708 11.9326C13.5121 12.1263 13.336 12.2554 13.1423 12.2554Z"
                              fill="white"
                            />
                            <path
                              d="M7.90495 14.3106H5.55693C3.41435 14.3106 2.18164 13.0778 2.18164 10.9353V5.94572C2.18164 3.80315 3.41435 2.57043 5.55693 2.57043H10.253C12.3955 2.57043 13.6283 3.80315 13.6283 5.94572V8.00024C13.6283 8.24091 13.4287 8.4405 13.188 8.4405C12.9473 8.4405 12.7478 8.24091 12.7478 8.00024V5.94572C12.7478 4.26688 11.9318 3.45094 10.253 3.45094H5.55693C3.87809 3.45094 3.06215 4.26688 3.06215 5.94572V10.9353C3.06215 12.6141 3.87809 13.43 5.55693 13.43H7.90495C8.14562 13.43 8.34521 13.6296 8.34521 13.8703C8.34521 14.111 8.14562 14.3106 7.90495 14.3106Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        <span class="fontsize-mini text-grey-8b pr-1"
                          >تاریخ</span
                        >
                        <span class="fontsize-mini text-grey-8b pr-1">{{
                          blog.published_at | Date
                        }}</span>
                      </div>

                      <div>
                        <span>
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.14009 6.36671H7.51603C6.298 6.36671 5.68164 5.67698 5.68164 4.31219V2.90827C5.68164 2.40931 5.74034 1.69512 6.25886 1.30378C6.69423 0.98093 7.32037 0.956471 8.2449 1.22552C9.45805 1.57772 10.4706 2.5903 10.8228 3.80345C11.0919 4.72309 11.0674 5.35413 10.7446 5.7846C10.3532 6.30801 9.63904 6.36671 9.14009 6.36671ZM7.16383 1.77339C6.96327 1.77339 6.80674 1.81252 6.70401 1.89079C6.51323 2.03265 6.42029 2.36529 6.42029 2.90827V4.31708C6.42029 5.41283 6.84098 5.63785 7.52093 5.63785H9.14498C9.68307 5.63785 10.0157 5.5449 10.1625 5.35413C10.3337 5.12911 10.319 4.67907 10.1282 4.01869C9.84449 3.05502 9.0129 2.21853 8.04923 1.93971C7.68725 1.8272 7.39374 1.77339 7.16383 1.77339Z"
                              fill="white"
                            />
                            <path
                              d="M5.59291 11.2586C5.33365 11.2586 5.0695 11.239 4.80535 11.195C2.80463 10.8721 1.17569 9.24806 0.852836 7.24734C0.43704 4.6743 2.09533 2.24801 4.63413 1.72949C4.8347 1.69035 5.02547 1.81754 5.0695 2.01321C5.10863 2.21377 4.98145 2.40454 4.78578 2.44857C2.63831 2.88882 1.2295 4.94335 1.58659 7.12994C1.86053 8.82248 3.2351 10.1971 4.92764 10.471C7.12402 10.8232 9.17365 9.40948 9.60901 7.25224C9.64815 7.05168 9.84382 6.92449 10.0395 6.96362C10.24 7.00276 10.3672 7.19843 10.3281 7.3941C9.86338 9.67853 7.86756 11.2586 5.59291 11.2586Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        <span class="fontsize-mini text-grey-8b pr-1"
                          >بازدید</span
                        >
                        <span
                          class="fontsize-mini text-grey-8b pr-1 font-weight-bold"
                          >{{ blog.views_count }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="w-100 continue-button">
                    <div class="d-flex align-items-center gap-5">
                      <div class="custom-line"></div>
                      <button
                        class="border-r-25 padding-t-1 padding-b-1 padding-r-10 padding-l-10"
                      >
                        <router-link
                          class="text-light"
                          :to="{
                            name: 'blogDetail',
                            params: { id: blog.id },
                            query: { slug: blog.slug },
                          }"
                          ><ArrowLeftIcon
                        /></router-link>
                      </button>
                    </div>
                  </div>
                  <div
                    class="card-tag fontsize-mini text-light padding-t-2 padding-b-2 padding-r-10 padding-l-10 border-r-25"
                  >
                    {{ blog.category.name }}
                  </div>
                </article>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import HeaderSection from "@/parts/Front/components/HeaderSection";
import { BSkeleton, BSkeletonImg, BRow, BCol } from "bootstrap-vue";
import ArrowLeftIcon from "@/parts/Front/components/Icon/ArrowLeftIcon.vue";
import Swiper from "swiper/swiper-bundle.min.js";
let breakpoints = {
  300: {
    slidesPerView: 1.2,
    spaceBetween: 10,
  },
  500: {
    slidesPerView: 2,
    spaceBetween: 15,
  },
  992: {
    slidesPerView: 3,
    spaceBetween: 20,
  },
  1200: {
    slidesPerView: 4,
    spaceBetween: 25,
  },
};
export default {
  components: {
    HeaderSection,
    BSkeleton,
    BSkeletonImg,
    BRow,
    BCol,
    ArrowLeftIcon,
  },
  name: "Weblogs",
  data() {
    return {
      slideWidth: null,
      slideHeight: null,
    };
  },
  watch: {
    blogs() {
      // پاک کردن سویپر برای نمایش درست اسلایدر
      this.initHeaderSwiper(true);
    },
    status(newValue) {
      if (!newValue) {
        this.setMaxWidth();
      }
    },
  },
  mounted() {
    this.initHeaderSwiper();
    window.addEventListener("resize", this.onResize);
    // get height of articles/
    let bodyWidth = document.body.offsetWidth;
    const articleImg = document.querySelectorAll(".article-img");
    if (bodyWidth < 767) {
      for (let iImg of articleImg) {
        let imgH = iImg.offsetWidth / 1.45;
        iImg.style.height = imgH + "px";
      }
    }

    window.addEventListener("resize", function () {
      // get height of articles/
      // let bodyWidth=document.body.offsetWidth;
      const articleImg = document.querySelectorAll(".article-img");
      for (let iImg of articleImg) {
        let imgH = iImg.offsetWidth / 1.45;
        iImg.style.height = imgH + "px";
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    blogSummaryLength() {
      return window.innerWidth < 550 ? 70 : 130;
    },
    blogs() {
      //دریافت اطلاعات برای قسمت بلاگ که در تمپلیت بالا وارد آبجکت پست از این آبجکت میشود
      return this.$store.getters["front/getHomeData"];
    },
    status() {
      ///نمایش لودینگ ها به کمک استاتوس
      return this.$store.getters["front/getHomeDataStatus"];
    },
  },
  methods: {
    initHeaderSwiper(kill = false) {
      this.initSwiper("header-swiper", kill, () => {
        try {
          if (this.slider) {
            this.slider.destroy();
          }
        } catch (e) {}
        this.slider = new Swiper(".swiper-weblog .swiper-container", {
          spaceBetween: 10,
          centeredSlides: true,
          centeredSlidesBounds: true,
          speed: 1000,
          loop: false,
          autoplay: {
            delay: 8000,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".swiper-weblog .swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".swiper-weblog .swiper-button-next",
            prevEl: ".swiper-weblog .swiper-button-prev",
          },
          breakpoints,
        });
      });
    },
    onResize() {
      this.setMaxWidth();
    },
    setMaxWidth() {
      this.$nextTick(() => {
        let swiperData = this.getSlidesPerView();
        let spaceBetween = swiperData.spaceBetween;
        let slidesPerView = swiperData.slidesPerView;
        let eachSlideWidth =
          (document.getElementById("blogArticleContent").getBoundingClientRect()
            .width -
            spaceBetween * (slidesPerView - 1)) /
          slidesPerView;
        this.slideHeight = eachSlideWidth * 1.5;
        this.slideWidth = eachSlideWidth;
      });
    },
    getSlidesPerView() {
      let c = Number.parseInt(window.innerWidth);
      let ok = 1;
      let max = -1;
      Object.keys(breakpoints).forEach((b) => {
        b = Number.parseInt(b);
        if (c > b && b > max) {
          ok = breakpoints[b];
          max = b;
        }
      });
      return ok;
    },
  },
};
</script>

<style scoped>
.weblog-card {
  position: relative;
  display: inline-block;
  aspect-ratio: 3/4;
  overflow: hidden;
}
.weblog-card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.9) 100%
  );
}
.weblog-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-info {
  position: absolute;
  bottom: 12%;
  width: 100%;
  /* color: #fff; */
}
.card-info p {
  color: var(--color-theme3);
}
.continue-button {
  padding: 0 10px;
  position: absolute;
  bottom: 4%;
  width: 100%;
}
.continue-button button {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.3);
}
.card-tag {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  top: 4%;
  right: 4%;
}
.custom-line {
  width: 100%;
  height: 1px;
  border-radius: 25px;
  background-color: #cccccc;
}
.continue-button .custom-line {
  width: 100%;
  height: 1px;
  border-radius: 25px;
  background-color: #cccccc73;
}
</style>
