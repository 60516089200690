<template>
  <DynamicLinker
    v-if="mediaQueries&&!mediaQueries.mobileSize && mobileItem && mobileItem.picture"
    :item="mobileItem"
  >
    <img
      :src="mobileItem?.picture"
      class="w-100 border-r-20"
      :alt="item?.position_description"
    />
  </DynamicLinker>
  <DynamicLinker v-else-if="item && item.picture" :item="item">
    <img
      :src="item?.picture"
      class="w-100 border-r-20"
      :alt="item?.position_description"
    />
  </DynamicLinker>

</template>

<script>
import DynamicLinker from "@/parts/Front/components/shared/DynamicLinker";
export default {
  name: "ResponsiveBanner",
  components: { DynamicLinker },
  inject: ["mediaQueries"],
  props: {
    item: Object,
    mobileItem: Object,
  },

};
</script>

<style scoped></style>
