<template>
  <div>
    <Header id="homeHeader" />
    <main class="main">
      <section class="container mt-2 mt-xl-5">
        <div class="row">
          <div class="col-12 col-xl-9"><HomeSlider /></div>
          <div class="col-12 col-xl-3 mt-5 mt-xl-0 benibox">
            <SingleAmazingProduct />
          </div>
        </div>
      </section>
      <!-- دسته بندی ها -->
      <div class="container">
        <ProductCategories />
      </div>
        <!-- محصولات پیشنهادی اول -->
        <div class="container">
          <template
            v-if="
              recommendations &&
              recommendations[1] &&
              recommendations[1].products &&
              recommendations[1].products.length
            "
          >
            <RecommendationSliders :data="recommendations[1]" />
          </template>
        </div>
           <!-- بنر تکی -->
      <div class="container banner_one">
        <ResponsiveBanner
          class="w-100"
          :item="access($store.getters, 'front/getHomeData.advertise.center')"
          :mobile-item="
            access($store.getters, 'front/getHomeData.advertise.center_mobile')
          "
        />
      </div>
      <!-- محصولات شگفت انگیز -->
      <RecommendedProductSlider class="mt-2 mt-lg-5" />
   
    
      <div>
        <QuadrupleBanner/>
      </div>
      <!-- محصولات ویژه و پکیج ها -->

      <div class="container mt-2 mt-lg-5">
        <template
          v-if="
            recommendations &&
            recommendations[5] &&
            recommendations[5].products &&
            recommendations[5].products.length
          "
        >
          <RecommendationSliders :data="recommendations[5]" />
        </template>
      </div>
      <!-- بنر تکی -->
      <banners class="container" 
      v-if="advertiseBanners && advertiseBanners.single_bottomBanner"
      :banner="advertiseBanners.single_bottomBanner"
      fullWidth

    />

      <!-- محصولات ویژه و پکیج ها -->
      <!-- <SpecialProduct class="mt-5 mt-lg-5" /> -->
      <!-- محصولات پیشنهادی دوم -->
      <div class="container">
        <template
          v-if="
            recommendations &&
            recommendations[2] &&
            recommendations[2].products &&
            recommendations[2].products.length
          "
        >
          <RecommendationSliders :data="recommendations[2]" />
        </template>
        <!-- بنر تکی -->
        <HomeBanners
          v-if="advertiseBanners"
          :banner="advertiseBanners.single_banner_1"
          fullWidth
        />
      </div>
      <!-- تب محصولات بندیتو -->
      <!-- <ProductTab class="mt-4 mt-lg-5" /> -->
      <div class="container mt-2 mt-lg-5">
        <template
          v-if="
            recommendations &&
            recommendations[6] &&
            recommendations[6].products &&
            recommendations[6].products.length
          "
        >
          <RecommendationSliders :data="recommendations[6]" />
        </template>
      </div>
      <!-- بنر های دوقلو -->
      <div class="container">
        <div
          v-if="advertiseBanners"
          class="d-flex align-items-center justify-content-between flex-column flex-md-row mt-2 mt-lg-5"
        >
          <HomeBanners :banner="advertiseBanners.bottom_right" />
          <HomeBanners
            :banner="advertiseBanners.bottom_left"
            class="mt-4 mt-md-0"
          />
        </div>
        <!-- محصولات پیشنهادی سوم -->
        <template
          v-if="
            recommendations &&
            recommendations[3] &&
            recommendations[3].products &&
            recommendations[3].products.length
          "
        >
          <RecommendationSliders :data="recommendations[3]" />
        </template>
      </div>
      <!-- تصاویر قبل و بعد -->
      <div>
        <BeforeAfter />
      </div>
      <!-- بنر های دوقلو -->
      <div
        v-if="advertiseBanners"
        class="container d-flex align-items-center justify-content-between flex-column flex-md-row mt-2 mt-lg-5"
      >
        <HomeBanners :banner="advertiseBanners.twin_banner_4" />
        <HomeBanners
          :banner="advertiseBanners.twin_banner_5"
          class="mt-4 mt-md-0"
        />
      </div>
      <!-- مشتریان معتبر -->
      <div class="mt-5"><TrustedCustomers /></div>
      <div class="container mt-5">
        <!-- بنر تکی -->
        <HomeBanners
          v-if="advertiseBanners"
          :banner="advertiseBanners.single_banner_6"
          fullWidth
        />
        <!-- محصولات پیشنهادی چهارم -->
        <template
          v-if="
            recommendations &&
            recommendations[4] &&
            recommendations[4].products &&
            recommendations[4].products.length
          "
        >
          <RecommendationSliders :data="recommendations[4]" />
        </template>
      </div>
      <!-- کپین ها -->
      <Flashes
        v-if="
          $store.getters['front/getHomeData'] != null &&
          $store.getters['front/getHomeData'].flashes != ''
        "
        :flashes="$store.getters['front/getHomeData'].flashes"
      />
      <!-- وبلاگ ها -->
      <Weblogs class="mt-5" />
    </main>
  </div>
</template>
<script>
import QuadrupleBanner from "../components/home/QuadrupleBanner.vue";
import Header from "@/parts/Front/components/shared/Header";
import Flashes from "@/parts/Front/components/FlashProducts";
import Weblogs from "@/parts/Front/components/Weblogs";
import ProductTab from "@/parts/Front/components/home/productsTab.vue";
import SpecialProduct from "@/parts/Front/components/home/SpecialProduct.vue";
import RecommendedProductSlider from "@/parts/Front/components/RecommendedProductSlider";
import HomeSlider from "@/parts/Front/components/home/HomeSlider.vue";
import SingleAmazingProduct from "@/parts/Front/components/home/SingleAmazingProduct.vue";
import ResponsiveBanner from "@/parts/Front/components/ResponsiveBanner";
import TrustedCustomers from "@/parts/Front/components/about-us/Trusted-customers";
import Banners from "@/parts/Front/components/Banners";
import banners from "../components/banner.vue";
import HomeBanners from "@/parts/Front/components/HomeBanners.vue";
import ProductCategories from "@/parts/Front/components/ProductCategories.vue";
import BeforeAfter from "@/parts/Front/components/home/BeforeAfter.vue";
import categoryItem from "@/parts/Front/components/home/categoryItem.vue";
import RecommendationSliders from "@/parts/Front/components/shared/RecommendationSliders.vue";
export default {
  name: "Home",
  components: {
    banners,
    QuadrupleBanner,
    RecommendedProductSlider,
    Header,
    Flashes,
    Weblogs,
    SpecialProduct,
    ProductTab,
    HomeSlider,
    SingleAmazingProduct,
    ResponsiveBanner,
    TrustedCustomers,
    Banners,
    HomeBanners,
    ProductCategories,
    BeforeAfter,
    categoryItem,
    RecommendationSliders,
  },
  mounted() {
    let savedPosition = localStorage.getItem("lastHomePosition");
    if (savedPosition) {
      setTimeout(() => {
        this.$nextTick(() => {
          window.scrollTo(0, parseInt(savedPosition, 10));
        });
      }, 500);
      window.addEventListener("scroll", this.setLastPosition);
    } else {
      window.addEventListener("scroll", this.setLastPosition);
    }
    if (this.$route.fullPath.includes("loginModalOpened")) {
      document.getElementById("loginButton").click();
    }
    if (!this.specialCategories) {
      this.$store.dispatch("front/getSpecialCategoriesFromServer");
    }
  },
  computed: {
    specialCategories() {
      return this.$store.getters["front/getSpecialCategories"];
    },
    banners() {
      if (this.$store.getters["front/getHomeData"] != null) {
        let advertise = this.$store.getters["front/getHomeData"].advertise;
        return [
          [advertise.center, advertise.position2],
          [advertise.position3, advertise.position4],
        ];
      } else {
        return [
          [null, null],
          [null, null],
        ];
      }
    },
    // customeBanners() {
    //   if (this.$store.getters["front/getHomeData"] != null) {
    //     let advertise = this.$store.getters["front/getHomeData"].advertise;
    //     return advertise;
    //   } else {
    //     return null;
    //   }
    // },
    advertiseBanners() {
      if (this.$store.getters["front/getHomeData"] != null) {
        return this.$store.getters["front/getHomeData"].advertise;
      } else {
        return {};
      }
    },
    recommendations() {
      if (
        this.$store.getters["front/getHomeData"] &&
        this.$store.getters["front/getHomeData"].recommendations
      ) {
        return this.$store.getters["front/getHomeData"].recommendations;
      } else {
        return null;
      }
    },
  },
  methods: {
    setLastPosition() {
      localStorage.setItem("lastHomePosition", window.scrollY);
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.setLastPosition);
  },
  
};
</script>
<style>
.banner_one{
  margin-bottom: 6rem !important;
}
.mt-8 {
  margin-top: 4.5rem !important;
}
@media (max-width: 768px) {
  .recommended-products {
    margin-top: 0 !important;
  }
  .benibox {
    padding: 8px;
    background-color: #ff294f;
  }
  #timedProduct {
    background-color: white !important;
    box-shadow: 0 0 5px white;
  }
  .benibox .text-white {
    color: var(--color-444) !important;
  }
  .benibox .discount-price {
    color: var(--color-999) !important;
  }
  .benibox .product-discount-tag {
    color: white !important;
  }
}
@media screen and (max-width:600px) {
  .banner_one{
    margin-bottom: 3rem !important;
  }
}
</style>
