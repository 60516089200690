<template>
  <main>
    <PagesPath :pathTitle1="'باشگاه مشتریان بندیتو'"></PagesPath>

    <section class="clubDetail">
      <div class="white-bg container">
        <div id="title1" class="pt-5 marginNegetive">
          <h1 class="container font-900 margin-b-50 pt-5">
            باشگاه مشتریان بندیتو
          </h1>
          <div class="row justify-content-around">
            <div class="col-12 col-lg-5">
              <p class="club-detail-paragraph">
                فروشگاه اینترنتی بندیتو با سابقه بالای 10 سال فعالیت ، همیشه به
                دنبال جلب رضایت مشتریان وفادار خود بوده است. باشگاه مشتریان
                بندیتو با هدف ارتقا کیفیت خدمات و ایجاد یک تجربه متفاوت و هیجان
                انگیز برای شما عزیزان ایجاد شد شما عزیزان به راحتی بعد از ثبت
                نام عضو باشگاه مشتریان بندیتو خواهید شد و با انجام ماموریت ها و
                کسب امتیاز میتونید به راحتی از خدمات باشگاه استفاده کنید.
              </p>
              <ul id="HelpList" class="py-3">
                <p
                  class="fontsize-heavy text-white m-0 py-3 px-4 table-bg-color-green"
                >
                  آنچه در باشگاه خواهید دید...
                </p>
                <li
                  class="fontsize-medium grey-text-color py-2 px-5 mt-1 table-bg-color-green-2"
                  style="cursor: pointer; list-style-type: none"
                  @click="findTitle(item.id)"
                  v-for="(item, index) in helpList"
                  :key="'item' + index"
                >
                  {{ item.title }}
                </li>
              </ul>
            </div>
            <div id="topTenCustomer" class="col-12 col-lg-6" v-if="topTen">
              <p
                class="w-100 text-center font-w-900 fontsize-heavy bg-color-theme text-light py-1 border-r-30"
              >
                برترین امتیازها
              </p>
              <div
                class="d-flex align-items-center gap-10 mb-2 mt-2 overflow-auto dateFilter"
              >
                <button
                  @click="changeTab('last_month')"
                  class="btn-light py-2 px-5 border-r-30 border"
                  :class="
                    activeTab == 'last_month' ? 'bg-color-theme text-light' : ''
                  "
                >
                  ماه
                </button>
                <button
                  @click="changeTab('last_year')"
                  class="btn-light py-2 px-5 border-r-30 border"
                  :class="
                    activeTab == 'last_year' ? 'bg-color-theme text-light' : ''
                  "
                >
                  سال
                </button>
                <button
                  @click="changeTab('all_time')"
                  class="btn-light py-2 px-5 border-r-30 border"
                  :class="
                    activeTab == 'all_time' ? 'bg-color-theme text-light' : ''
                  "
                >
                  کل
                </button>
                <router-link
                  v-if="isLogin"
                  class="btn-light py-2 px-5 border-r-30 border w-s-nowrap"
                  to="/club-panel"
                  >امتیاز من</router-link
                >
                <a
                  @click="showLogin()"
                  v-else
                  class="btn-light py-2 px-5 border-r-30 border w-s-nowrap"
                  >امتیاز من</a
                >
              </div>
              <div
                class="w-100 club-table-container"
                v-if="topTen[activeTab] && topTen[activeTab].length"
              >
                <table class="table">
                  <thead>
                    <tr class="table-bg-color-pink">
                      <th>ردیف</th>
                      <th>کاربر</th>
                      <th class="hideInMobile">سطح</th>
                      <th>امتیاز</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="table-bg-color-pink-2 w-s-nowrap"
                      v-for="(item, index) in topTen[activeTab]"
                      :key="index"
                    >
                      <td>{{ index + 1 }}</td>
                      <td class="nameAndLevel">
                        <div
                          class="d-flex align-items-center justify-content-center flex-column gap-3 mx-auto"
                        >
                          <span>{{ item.customer }}</span>
                          <span class="dir-ltr">{{ item.dashed_mobile }}</span>
                        </div>
                        <div
                          v-if="item.customers_club_level.image"
                          class="club-image-holder showInMobile"
                        >
                          <img
                            v-if="item.customers_club_level.image"
                            :id="'club-level-' + index"
                            class="w-100 h-100"
                            :src="`https://api.benedito.ir/${item.customers_club_level.image}`"
                            alt=""
                          />
                        </div>
                      </td>
                      <td class="hideInMobile">
                        <div
                          class="d-flex align-items-center justify-content-center flex-column gap-3 mx-auto"
                        >
                          <div
                            v-if="item.customers_club_level.image"
                            class="club-image-holder"
                          >
                            <img
                              v-if="item.customers_club_level.image"
                              :id="'club-level-' + index"
                              class="w-100 h-100"
                              :src="`https://api.benedito.ir/${item.customers_club_level.image}`"
                              alt=""
                            />
                          </div>
                          <!-- <b-tooltip
                            v-if="item.customers_club_level.image"
                            :target="'club-level-' + index"
                            triggers="hover"
                          >
                            {{ item.customers_club_level.level }}
                          </b-tooltip> -->
                          <span>{{ item.customers_club_level.level }}</span>
                        </div>
                      </td>
                      <td>{{ item.score }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else class="alert alert-info" role="alert">
                موردی یافت نشد!
              </div>
            </div>
          </div>
        </div>
        <div class="clubDetail-cards pt-5 marginNegetive" id="title2">
          <h2 class="font-900 mb-4 pt-5">خدمات باشگاه مشتریان بندیتو</h2>
          <div>
            <p class="club-detail-paragraph">
              باشگاه مشتریان بندیتو دارای چهار سطح ( سفید، برنزی، نقره ای و
              طلایی ) میباشد. شما با هر بار خرید در فروشگاه بندیتو و کسب امتیاز
              لازم، طبق قوانین باشگاه در یکی از سطوح قرار می گیرید.
            </p>
          </div>
          <div class="d-none d-lg-block scoringTable">
            <table class="w-100">
              <thead>
                <tr class="table-bg-color-pink">
                  <th class="fontsize-heavy">خدمات</th>
                  <th v-for="(level, index) in levels" :key="index">
                    <div
                      class="d-flex align-items-center justify-content-center flex-column gap-3"
                    >
                      <div class="image-holder">
                        <img
                          class="w-100 h-100"
                          :src="level.image"
                          :alt="level.title"
                        />
                      </div>
                      <span>{{ level.title }}</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="table-bg-color-pink-2"
                  v-for="(plan, index) in plansTable"
                  :key="index"
                >
                  <td class="font-w-800">{{ plan.title }}</td>
                  <td v-for="(value, index) in plan.values" :key="index">
                    <emphasis v-if="value.value == true" />
                    <DeletedClubDetail v-else-if="value.value == false" />
                    <span v-else>{{ value.value }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-block d-lg-none scoringTable" id="mobilePlanTable">
            <div id="levelTabs">
              <button
                @click="changeLevelTab(0)"
                class="btn-light py-2 px-5 border-r-30 border"
                :class="activeLevelTab == 0 ? 'bg-color-theme text-light' : ''"
              >
                سفید
              </button>

              <button
                @click="changeLevelTab(1)"
                class="btn-light py-2 px-5 border-r-30 border"
                :class="activeLevelTab == 1 ? 'bg-color-theme text-light' : ''"
              >
                برنزی
              </button>

              <button
                @click="changeLevelTab(2)"
                class="btn-light py-2 px-5 border-r-30 border"
                :class="activeLevelTab == 2 ? 'bg-color-theme text-light' : ''"
              >
                نقره ای
              </button>

              <button
                @click="changeLevelTab(3)"
                class="btn-light py-2 px-5 border-r-30 border"
                :class="activeLevelTab == 3 ? 'bg-color-theme text-light' : ''"
              >
                طلایی
              </button>
            </div>
            <div class="swiper">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <!-- جدول سطح سفید -->
                    <table class="w-100">
                      <div class="border-r-50 overflow-hidden">
                        <thead>
                          <tr class="table-bg-color-pink">
                            <th class="fontsize-large">خدمات</th>
                            <th
                              class="d-flex align-items-center justify-content-center flex-column overflow-hidden"
                            >
                              <div class="image-holder">
                                <img
                                  class="w-100 h-100"
                                  :src="levels[0].image"
                                  :alt="levels[0].title"
                                />
                              </div>
                              <span>{{ levels[0].title }}</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="table-bg-color-pink-2"
                            v-for="(plan, index) in plansTable"
                            :key="index"
                          >
                            <td class="font-w-800">{{ plan.title }}</td>
                            <td>
                              <emphasis v-if="plan.values[0].value == true" />
                              <DeletedClubDetail
                                v-else-if="plan.values[0].value == false"
                              />
                              <span v-else>{{ plan.values[0].value }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </div>
                    </table>
                  </div>
                  <div class="swiper-slide">
                    <!-- جدول سطح برنزی -->
                    <table class="w-100">
                      <div class="border-r-50 overflow-hidden">
                        <thead>
                          <tr class="table-bg-color-pink">
                            <th class="fontsize-large">خدمات</th>
                            <th
                              class="d-flex align-items-center justify-content-center flex-column"
                            >
                              <div class="image-holder">
                                <img
                                  class="w-100 h-100"
                                  :src="levels[1].image"
                                  :alt="levels[1].title"
                                />
                              </div>
                              <span>{{ levels[1].title }}</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="table-bg-color-pink-2"
                            v-for="(plan, index) in plansTable"
                            :key="index"
                          >
                            <td class="font-w-800">{{ plan.title }}</td>
                            <td>
                              <emphasis v-if="plan.values[1].value == true" />
                              <DeletedClubDetail
                                v-else-if="plan.values[1].value == false"
                              />
                              <span v-else>{{ plan.values[1].value }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </div>
                    </table>
                  </div>
                  <div class="swiper-slide">
                    <!-- جدول سطح نقره ای -->
                    <table class="w-100">
                      <div class="border-r-50 overflow-hidden">
                        <thead>
                          <tr class="table-bg-color-pink">
                            <th class="fontsize-large">خدمات</th>
                            <th
                              class="d-flex align-items-center justify-content-center flex-column"
                            >
                              <div class="image-holder">
                                <img
                                  class="w-100 h-100"
                                  :src="levels[2].image"
                                  :alt="levels[2].title"
                                />
                              </div>
                              <span>{{ levels[2].title }}</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="table-bg-color-pink-2"
                            v-for="(plan, index) in plansTable"
                            :key="index"
                          >
                            <td class="font-w-800">{{ plan.title }}</td>
                            <td>
                              <emphasis v-if="plan.values[2].value == true" />
                              <DeletedClubDetail
                                v-else-if="plan.values[2].value == false"
                              />
                              <span v-else>{{ plan.values[2].value }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </div>
                    </table>
                  </div>
                  <div class="swiper-slide">
                    <!-- جدول سطح طلایی -->
                    <table class="w-100">
                      <div class="border-r-50 overflow-hidden">
                        <thead>
                          <tr class="table-bg-color-pink">
                            <th class="fontsize-large">خدمات</th>
                            <th
                              class="d-flex align-items-center justify-content-center flex-column"
                            >
                              <div class="image-holder">
                                <img
                                  class="w-100 h-100"
                                  :src="levels[3].image"
                                  :alt="levels[3].title"
                                />
                              </div>
                              <span>{{ levels[3].title }}</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="table-bg-color-pink-2"
                            v-for="(plan, index) in plansTable"
                            :key="index"
                          >
                            <td class="font-w-800">{{ plan.title }}</td>
                            <td>
                              <emphasis v-if="plan.values[3].value == true" />
                              <DeletedClubDetail
                                v-else-if="plan.values[3].value == false"
                              />
                              <span v-else>{{ plan.values[3].value }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </div>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="Description-performance-clubDetail">
          <div
            class="Description-performance-title pt-5 marginNegetive"
            id="title3"
          >
            <h2 class="font-weight-bold mb-3 pt-5">
              شرح عملکرد باشگاه مشتریان:
            </h2>
            <p class="club-detail-paragraph">
              در باشگاه مشتریان بندیتو دو نوع هدیه بابت عملیاتی که کاربر در وب
              سایت انجام می دهد در نظر گرفته شده است. این دو نوع عبارتند از بن و
              امتیاز.
            </p>
          </div>
          <div id="title4" class="marginNegetive pt-5">
            <h3 class="font-weight-bold margin-b-20 pt-5">بن:</h3>

            <p class="club-detail-paragraph">
              شما میتوانید با انجام دادن
              <span class="text-color-theme cursor-pointer" @click="goTo"
                >ماموریت ها</span
              >
              بن هدیه به دست آورید
            </p>
            <p class="club-detail-paragraph">
              این هدیه به این معنی است که پس از جمع کردن مقداری بن ، شما میتونید
              درخواست تبدیل بن به پول بدهید و پشتیبان سایت پس از بررسی بن را
              تبدیل به پول در کیف پول هدیه شما خواهد کرد و شما میتونید از آن در
              خرید های خود بهره ببرید
            </p>
          </div>
          <div id="title5" class="marginNegetive pt-5">
            <h3 class="font-weight-bold margin-b-20 pt-5">امتیاز:</h3>
            <p class="club-detail-paragraph">
              این نوع امتیاز جهت بالا بردن سطح عضویت باشگاه مشتریان میباشد
            </p>
            <p class="club-detail-paragraph">
              شما میتوانید با انجام دادن ماموریت ها امتیاز خود را افزایش دهید.
            </p>
          </div>
        </div>
        <div
          id="title6"
          class="HowTo-score-points-clubDetail pt-5 marginNegetive"
        >
          <div class="scoringTable pt-5">
            <div>
              <h2 class="font-weight-bold mb-3">
                ماموریت ها برای دریافت امتیاز و بن
              </h2>
              <p
                class="font-weight-bold font-w-900 margin-b-50 mb-tab-30 font-s-18"
              >
                جدول 1 : عملیاتی که منجر به دریافت امتیاز یا بن در باشگاه می شود
              </p>
            </div>
            <table class="table1-score-points">
              <thead>
                <tr class="table-bg-color-pink">
                  <th>ردیف</th>
                  <th>نحوه امتیاز گیری</th>
                  <th>امتیاز</th>
                  <th>بن</th>
                </tr>
              </thead>
              <tbody>
                <tr class="table-bg-color-pink-2" v-for="item in table1">
                  <td>
                    {{ item.row }}
                  </td>
                  <td>
                    {{ item.des }}
                  </td>
                  <td>
                    {{ item.point }}
                  </td>
                  <td>
                    {{ item.bon }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="margin-t-20 d-flex justify-content-center align-items-center gap-10"
          >
            <p class="margin-b-0 font-weight-bold">از همین حالا میتونی شروع کنی</p>
            <router-link
              class="btn-light py-2 px-5 border-r-30 border bg-color-theme text-light"
              to="/club-missions"
              >بزن بریم</router-link
            >
          </div>
          <div class="margin-t-50 mt-tab-30 scoringTable">
            <p
              class="font-weight-bold font-w-900 margin-b-40 mb-tab-30 font-s-18"
            >
              جدول2 : - امتیاز و بن دریافتی بابت خرید از وب سایت
            </p>
            <table class="table2-score-points">
              <thead>
                <tr class="table-bg-color-green w-s-nowrap">
                  <th>مبلغ خرید (تومان)</th>
                  <th>امتیاز</th>
                  <th>بن</th>
                </tr>
              </thead>
              <tbody>
                <tr class="table-bg-color-green-2" v-for="item in table2">
                  <td>
                    {{ item.price }}
                  </td>
                  <td>
                    {{ item.point }}
                  </td>
                  <td>
                    {{ item.bon }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import DeletedClubDetail from "@/parts/Front/components/Icon/DeletedClubDetail.vue";
import emphasis from "@/parts/Front/components/Icon/emphasis.vue";
import PagesPath from "@/parts/Front/components/PagesPath";
import whiteImg from "@/assets/images/clubDetail/logoColorizedWhite.png";
import bronzeImg from "@/assets/images/clubDetail/logoColorizedBronze.png";
import silverImg from "@/assets/images/clubDetail/logoColorizedSilver.png";
import goldImg from "@/assets/images/clubDetail/logoColorizedGold.png";
import { BTooltip } from "bootstrap-vue";
import config from "../../../config.js";
import Swiper from "swiper/swiper-bundle.min.js";

export default {
  name: "ClubDetail",
  components: {
    DeletedClubDetail,
    emphasis,
    PagesPath,
    BTooltip,
  },
  data() {
    return {
      activeTab: "last_month",
      activeLevelTab: 0,
      swiper: null,
      table1: [
        {
          row: "1",
          des: "ثبت نام در وب سایت و تکمیل پروفایل",
          point: "200",
          bon: "2",
        },
        // { row: "2", des: "خرید از وب سایت", point: "-", bon: "2" },
        {
          row: "3",
          des: "نوشتن نظرات برای محصولات خریداری شده",
          point: "200",
          bon: "2",
        },
        {
          row: "4",
          des: "عکس قبل و بعد از مصرف محصولات",
          point: "300",
          bon: "3",
        },
        {
          row: "5",
          des: "فرستادن دعوت نامه برای دوستان",
          point: "100",
          bon: "1",
        },
        {
          row: "6",
          des: "دعوت از دوستان که منجر به ثبت نام و خرید بشن",
          point: "1000",
          bon: "10",
        },
        {
          row: "7",
          des: "منشن کردن پیج ما در استوری به مدت 24 ساعت ( بامحدودیت یکبار در هفته )",
          point: "500",
          bon: "5",
        },
        { row: "8", des: "شرکت در نظر سنجی اینماد", point: "200", bon: "2" },
        {
          row: "9",
          des: "شرکت در تکمیل پرسشنامه ها و مسابقات",
          point: "300",
          bon: "3",
        },
        { row: "10", des: "لاگین شدن روزانه در pwa", point: "100", bon: "1" },
      ],
      table2: [
        { price: "زیر 100 هزار", point: "100", bon: "1" },
        { price: "بین 100 تا 499 هزار   ", point: "200", bon: "2" },
        { price: "بین 500 تا 999 هزار", point: "400", bon: "4" },
        { price: "بالای 1 میلیون", point: "600", bon: "6" },
      ],
      levels: [
        {
          id: 1,
          title: "سطح سفید",
          image: whiteImg,
        },
        {
          id: 2,
          title: "سطح برنزی",
          image: bronzeImg,
        },
        {
          id: 3,
          title: "سطح نقره ای",
          image: silverImg,
        },
        {
          id: 4,
          title: "سطح طلایی",
          image: goldImg,
        },
      ],
      plansTable: [
        {
          id: 1,
          title: "% تخفیف دائمی پولی برگشت از فاکتور خرید",
          values: [
            {
              plan_id: 1,
              value: "3%",
            },
            {
              plan_id: 2,
              value: "5%",
            },
            {
              plan_id: 3,
              value: "6%",
            },
            {
              plan_id: 4,
              value: "7%",
            },
          ],
        },
        {
          id: 2,
          title: "تخفیف تاریخ تولد",
          values: [
            {
              plan_id: 1,
              value: "10%",
            },
            {
              plan_id: 2,
              value: "15%",
            },
            {
              plan_id: 3,
              value: "20%",
            },
            {
              plan_id: 4,
              value: "25%",
            },
          ],
        },
        {
          id: 3,
          title: " ارسال رایگان",
          values: [
            {
              plan_id: 1,
              shippingLevelPrice: config.shippingLevelPrice[0],
              value:
                config.shippingLevelPrice[0].toLocaleString("fa-IR") + " تومان",
            },
            {
              plan_id: 2,
              shippingLevelPrice: config.shippingLevelPrice[1],
              value:
                config.shippingLevelPrice[1].toLocaleString("fa-IR") + " تومان",
            },
            {
              plan_id: 3,
              shippingLevelPrice: config.shippingLevelPrice[2],
              value:
                config.shippingLevelPrice[2].toLocaleString("fa-IR") + " تومان",
            },
            {
              plan_id: 4,
              shippingLevelPrice: config.shippingLevelPrice[3],
              value:
                config.shippingLevelPrice[3].toLocaleString("fa-IR") + " تومان",
            },
          ],
        },
        {
          id: 4,
          title: "اطلاعیه از شگفت انگیز ها",
          values: [
            {
              plan_id: 1,
              value: false,
            },
            {
              plan_id: 2,
              value: true,
            },
            {
              plan_id: 3,
              value: true,
            },
            {
              plan_id: 4,
              value: true,
            },
          ],
        },
        {
          id: 5,
          title: "اطلاعیه از بنی باکس",
          values: [
            {
              plan_id: 1,
              value: false,
            },
            {
              plan_id: 2,
              value: true,
            },
            {
              plan_id: 3,
              value: true,
            },
            {
              plan_id: 4,
              value: true,
            },
          ],
        },
        {
          id: 6,
          title: "اطلاعیه لانچ محصولات جدید با تخفیف",
          values: [
            {
              plan_id: 1,
              value: false,
            },
            {
              plan_id: 2,
              value: false,
            },
            {
              plan_id: 3,
              value: true,
            },
            {
              plan_id: 4,
              value: true,
            },
          ],
        },
        {
          id: 7,
          title: "تستر رایگان محصولات مصرفی جدید",
          values: [
            {
              plan_id: 1,
              value: false,
            },
            {
              plan_id: 2,
              value: false,
            },
            {
              plan_id: 3,
              value: false,
            },
            {
              plan_id: 4,
              value: true,
            },
          ],
        },
        {
          id: 8,
          title: "هدایای نفیس سالانه",
          values: [
            {
              plan_id: 1,
              value: false,
            },
            {
              plan_id: 2,
              value: false,
            },
            {
              plan_id: 3,
              value: false,
            },
            {
              plan_id: 4,
              value: true,
            },
          ],
        },
        {
          id: 9,
          title: "قرعه کشی بزرگ سالانه",
          values: [
            {
              plan_id: 1,
              value: false,
            },
            {
              plan_id: 2,
              value: false,
            },
            {
              plan_id: 3,
              value: false,
            },
            {
              plan_id: 4,
              value: true,
            },
          ],
        },
        {
          id: 10,
          title: "توضیحات",
          values: [
            {
              plan_id: 1,
              value: "مشتریان با کمتر از 5 هزار امتیاز",
            },
            {
              plan_id: 2,
              value: "بین 5 هزار تا 10 هزار امتیاز",
            },
            {
              plan_id: 3,
              value: "بین 10 هزار تا 15 هزار امتیاز",
            },
            {
              plan_id: 4,
              value: "مشتریان با بیشتر از 15 هزار امتیاز",
            },
          ],
        },
      ],
      helpList: [
        {
          id: "title1",
          title: "باشگاه مشتریان بندیتو",
        },
        {
          id: "title2",
          title: "خدمات باشگاه مشتریان بندیتو",
        },
        {
          id: "title3",
          title: "شرح عملکرد باشگاه مشتریان",
        },
        {
          id: "title4",
          title: "بن",
        },
        {
          id: "title5",
          title: "امتیاز",
        },
        {
          id: "title6",
          title: "ماموریت ها برای دریافت امتیاز و بن",
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("front/getClubTopTenFromServer");
    this.initHeaderSwiper();
  },
  computed: {
    topTen() {
      return this.$store.getters["front/getClubTopTen"];
    },
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
  },
  methods: {
    changeLevelTab(slideNumber) {
      this.activeLevelTab = slideNumber;
      this.swiper.slideTo(slideNumber);
    },
    initHeaderSwiper(kill = false) {
      this.initSwiper("header-swiper", kill, () => {
        this.swiper = new Swiper("#mobilePlanTable  .swiper-container", {
          spaceBetween: 10,
          speed: 1000,
          loop: false,
          centeredSlides: true,
          autoplay: {
            delay: 4500,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".swiper .swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".swiper .swiper-button-next",
            prevEl: ".swiper .swiper-button-prev",
          },
        });
      });
    },
    showLogin() {
      document.querySelector('button[data-target="#loginModal"]').click();
    },
    changeTab(tab) {
      if (this.activeTab != tab) this.activeTab = tab;
    },
    findTitle(id) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    },
    goTo() {
      if (this.isLogin) {
        this.$router.push("/club-missions");
      } else {
        // this.$root.notify('ابتدا وارد شوید', 'success');
        window.$("#loginModal").modal("show");
      }
    },
  },
};
</script>
<style scoped>
.font-900 {
  font-weight: 900;
}
.clubDetail {
  margin-top: 5rem;
}
.club-detail-paragraph {
  text-align: justify;
  line-height: 2;
  font-size: 18px;
  color: var(--footer-text-color);
}
.clubDetail-cards-content {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 5rem;
  width: 85%;
  margin: 3rem auto 1rem;
}

.clubDetail-card {
  width: 100%;
}
.clubDetail-card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.clubDetail-card-info {
  box-shadow: 2px 4px 74px 0px #aeaeae40;
  background-color: #fff;
  border-radius: 50px;
  padding: 1rem 0;
}
.clubDetail-card-info nav {
  padding: 1rem 2rem;
}
.clubDetail-card li {
  list-style-type: none;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.clubDetail-card-info img {
  width: 90px !important;
}
.clubDetail-card-info h3 {
  font-weight: 700;
  padding-right: 2rem;
}
.caption-clubDetail-card {
  text-align: center;
  padding: 2rem;
  padding-bottom: 0;
  font-weight: 600;
  font-size: 18px;
}

.table1-score-points {
  width: 75%;
  text-align: center;
  margin: auto;
}
.table1-score-points td,
.table1-score-points th {
  padding: 8px;
}
.table2-score-points {
  width: 50%;
  text-align: center;
  margin: auto;
}
.table2-score-points td,
.table2-score-points th {
  padding: 8px;
}
.item-rules {
  list-style: decimal;
  list-style-position: inside;
}
#topTenCustomer .dir-ltr {
  direction: ltr;
}
#topTenCustomer .club-table-container,
.scoringTable {
  overflow-x: auto;
}
#topTenCustomer table tr td,
.scoringTable table tr td {
  padding: 0.8rem 1.5rem;
}
#topTenCustomer table,
th,
td {
  /* border: 2px solid var(--color-main) !important; */
  border-collapse: separate;
  border-spacing: 5px 5px;
  text-align: center;
}
.scoringTable table,
th,
td {
  /* border: 2px solid var(--color-main) !important; */
  border-collapse: separate;
  border-spacing: 5px 5px;
  text-align: center;
}
#topTenCustomer table tr th,
.scoringTable table tr th {
  color: #ffffff;
}
#topTenCustomer table tr td,
.scoringTable table tr td {
  color: var(--footer-text-color);
  font-weight: 600;
  border: none !important;
}
/* #topTenCustomer table tr:first-child th:first-child,
.scoringTable table tr:first-child th:first-child {
  border-top-right-radius: 30px !important;
}
#topTenCustomer table tr:first-child th:last-child,
.scoringTable table tr:first-child th:last-child {
  border-top-left-radius: 30px !important;
}
#topTenCustomer table tr:last-child td:first-child,
.scoringTable table tr:last-child td:first-child {
  border-bottom-right-radius: 30px !important;
}
#topTenCustomer table tr:last-child td:last-child,
.scoringTable table tr:last-child td:last-child {
  border-bottom-left-radius: 30px !important;
} */
#topTenCustomer table,
.scoringTable table {
  border: none !important;
}
#mobilePlanTable table tr td {
  white-space: wrap;
  padding: 0.5rem 1rem;
}
#topTenCustomer .club-image-holder {
  width: 50px;
}
.table-bg-color-green {
  background-color: rgb(21, 190, 149);
}
.table-bg-color-green-2 {
  background-color: rgba(21, 190, 149, 0.3);
}
.table-bg-color-pink {
  background-color: #eb6fa7;
}
.table-bg-color-pink-2 {
  background-color: rgba(235, 111, 167, 0.3);
}
#HelpList li:last-child {
  border-radius: 0 0 30px 30px;
}
#HelpList p {
  border-radius: 30px 30px 0 0;
}
.image-holder {
  width: 90px;
}
.clubDetail .marginNegetive {
  margin-top: -65px;
}

@media screen and (max-width: 1250px) {
  .clubDetail-cards-content {
    width: 100%;
  }
}
@media screen and (max-width: 1000px) {
  .clubDetail-cards-content {
    grid-template-columns: repeat(1, minmax(0, 1fr));

    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .clubDetail-cards-content {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 3rem;

    width: 85%;
  }
  .table1-score-points {
    width: 95%;
  }
  .table2-score-points {
    width: 95%;
  }
  .mb-tab-30 {
    margin-bottom: 30px;
  }
  .mt-tab-30 {
    margin-top: 30px;
  }
  .dateFilter {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .dateFilter button {
    padding: 4px 8px !important;
  }

  .dateFilter a {
    grid-column: 1/4;
    text-align: center;
    background-color: var(--color-theme);
    color: white;
  }
  div#levelTabs button {
    font-size: 12px;
    padding: 8px !important;
  }
  div#levelTabs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 4px;
  }
}
.showInMobile {
  display: none;
}
@media screen and (max-width: 500px) {
  .clubDetail-cards-content {
    grid-template-columns: repeat(1, minmax(0, 1fr));

    width: 95%;
  }
  .clubDetail-card-info h3 {
    font-size: 22px;
    font-weight: 900;
  }
  .clubDetail-card p {
    font-weight: 900;
    font-size: 16px !important;
  }
  .clubDetail-card li {
    font-size: 14px;
  }

  .clubDetail h3 {
    font-size: 22px;
  }
  .clubDetail p {
    font-size: 14px;
  }

  .HowTo-score-points-clubDetail p {
    font-size: 16px;
  }
  .table2-score-points,
  .table1-score-points,
  .Scoring-rules li {
    font-size: 14px !important;
  }
  .hideInMobile {
    display: none;
  }
  .showInMobile {
    display: block;
  }
  td.nameAndLevel {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
<style>
.ClubDetail-body {
  background-image: unset;
}
</style>
